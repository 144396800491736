<template>
    <div class="customerWalletAddress">
        <!-- 面包屑导航区 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户钱包地址</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-row>
                <el-col :span="8">
                    <el-input placeholder="请输入内容" v-model="address" class="input-with-select">
                        <el-button slot="append" @click="_getCustomerscurrencyaddresses"
                            icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
            <el-button type="primary" @click="_batchDeletion">批量删除</el-button>
          </el-col>
            </el-row>
        </el-card>

        <el-table :data="custormdata" header-align="center" ref="addressCustomer" style="width: 100%">
            <el-table-column type="selection" width="80"></el-table-column>
            <el-table-column label="银行卡编号" prop="bank_card_id"></el-table-column>
            <el-table-column label="银行卡类型" prop="bank_card_type_code"></el-table-column>
            <el-table-column label="币种" prop="bank_code"></el-table-column>
            <el-table-column label="客户" prop="name">
                <template slot-scope="scope">
                    <span>{{ scope.row.name }}({{ scope.row.customer_id }})</span>
                </template>
            </el-table-column>
            <el-table-column label="网络协议" prop="network"></el-table-column>
            <el-table-column label="是否固定" prop="is_fixed">
                <template slot-scope="scope">
                    <el-switch @change="exchangeFixed(scope.row)" v-model="scope.row.is_fixed" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="白名单" prop="is_white">
                <template slot-scope="scope">
                    <el-switch @change="exchangeWhite(scope.row)" v-model="scope.row.is_white" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
            <!-- <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button type="warn" @click="deleteCutorm(scope.row)">删除</el-button>
                </template>
            </el-table-column> -->
        </el-table>

    </div>
</template>
<script>
export default {

    data() {
        return {
            address: "",
            custormdata: [],
        };
    },
    mounted(){
        if (this.$route.query.no) {
      this.address = this.$route.query.no;
      this._getCustomerscurrencyaddresses();
    } else {
      this.formLabelAlign.no = '';
    } 
    },                                                                      
    methods: {
        async _getCustomerscurrencyaddresses() {
            const { data: res } = await this.$http.get("/api/CustomerBankCard/GetCustomerByVirtual", {
                params: {
                    // TMoqSFqBaxWEscNQwaQXX84qctrDs3UZZZ
                    address: this.address
                }
            });
            if (res.code !== 1) {
                this.$message.success(res.message);
            }
            this.custormdata = res.data;

        },
        // 修改是否固定
   async exchangeFixed(row) {

     const { data: res } = await this.$http.post("/api/CustomerBankCard/SetFixCustomerBankCard", {
      
        bank_card_id: row.bank_card_id,
        customer_id: row.customer_id,
        is_fixed: row.is_fixed ,
             
       
      });
      if (res.code == 1) {
        this.$message.success("修改成功");

        this._getCustomerscurrencyaddresses();
      } else {
        this.$message.error(res.message);
      }
        },    

        // 修改是否白名单
        async exchangeWhite(row) {
            const { data: res } = await this.$http.post("/api/CustomerBankCard/SetWhiteCustomerBankCard", {
                bank_card_id: row.bank_card_id,
                customer_id: row.customer_id,
                is_white: row.is_white,
         });
         if (res.code == 1) {
            this.$message.success("修改成功");

            this._getCustomerscurrencyaddresses();
        } else {
            this.$message.error(res.message);
        }
        },    
            // 获取单选选中的数据
    getSelectedRows() {
      const selectedRows = this.$refs.addressCustomer.selection;
      return selectedRows;
    },
     // 删除客户
     async _batchDeletion() {
        const result = await this.$confirm("此操作将解除客户地址关联, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        });
        if (result) {
            let selectedRows = this.getSelectedRows();
     let delteddata = selectedRows.map(item => (
          
        {
            bank_card_id: item.bank_card_id,
            customer_id: item.customer_id,
        }
        
      ));
    //   console.log(delteddata,"addUD");
      
            const { data: res } = await this.$http.post("/api/CustomerBankCard/RemoveCustomerBankCardRelation", delteddata);
            if (res.code == 1) {
                this.$message.success("删除成功");

                this._getCustomerscurrencyaddresses();
            } else {
                this.$message.error(res.message);
            }

        } else {
            return;

        }
      }
  },

};

</script>
<style lang="less" scoped>
/deep/.el-checkbox__inner {
  width: 50px;
  /* 调整宽度 */
  height: 50px;
  /* 调整高度 */
}
</style>