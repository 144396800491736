<template>
  <div class="UserProfile">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>

      <el-row>
        <!-- <el-col :span="6">
            <input type="number" @keyup.enter="handleEnter">
            <el-input placeholder="请输入客户编号" v-model="formLabelAlign.no" @change="handleEnter" class="input-with-select">
              <el-button slot="append" @click="_customernamedate" icon="el-icon-search"></el-button>
            </el-input>
          </el-col> -->
        <el-col :span="6">
          <input style="height: 40px; width: 220px;" type="number" placeholder="请输入客户编号" v-model="formLabelAlign.no"
            @keyup.enter="handleEnter">
          <el-button type="primary" icon="el-icon-search" @click="_customernamedate">搜索</el-button>
        </el-col>
        <el-col :span="6">

        </el-col>

      </el-row>

    </el-card>
    <el-card style="margin-top: 10px;">
      <template>
        <div id="app">
          <h1>个人基本信息展示</h1>
          <div style="display: flex; justify-content: space-around;">
            <p>国籍:{{ kehujibenxixin.countryCn }}</p>
            <p>姓名：{{ kehujibenxixin.name }}({{ kehujibenxixin.no }})</p>
            <p>联系方式:{{ kehujibenxixin.phoneNumber }}</p>
            <p>性别:{{ kehujibenxixin.sex == 11 ? '男' : '女' }}</p>
          </div>
        </div>
      </template>

    </el-card>
    <el-card style="margin-top: 10px;">
      <el-tabs type="border-card" @tab-click="fenxianhandleClick">
        <el-tab-pane label="身份证管理">
          <template>
            <div>
              <div v-for="(credential, index) in credentiales" :key="index">
                <el-button type="success" @click="showCredentialInfo(credential)">
                  {{ getGeneralStructureType(credential.generalStructureType) }}
                </el-button>
              </div>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane label="风险">
          <template>
            <el-tabs v-model="activeName">
              <el-tab-pane label="道琼斯" name="first">
                <el-table :data="daoqiongsidata" border style="width: 100%">
                  <el-table-column prop="startime" align="center" label="时间">
                  </el-table-column>
                  <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                      <el-button size="mini" @click="fengxianpingguxiangqing(scope.row)">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination v-if="showPagination" :page-size="10" layout="prev, pager, next"
                  :total="daoqiongsipage.total" @current-change="handleCurrentChange">
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="风险评估" name="second">
                <el-table :data="fengxianpinggudata" border style="width: 100%">
                  <el-table-column prop="nick" label="操作人" align="center">
                  </el-table-column>
                  <el-table-column prop="customer_survey_time" align="center" label="评估时间">
                  </el-table-column>
                  <el-table-column prop="total_score" align="center" label="总得分">
                  </el-table-column>
                  <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                      <el-button size="mini" @click="fenxianpingguxiangqing(scope.row)">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="客户调查表" name="third">
                <el-table :data="kehudiaochabiaodata1" border style="width: 100%">
                  <el-table-column prop="nick" label="操作人" align="center">
                  </el-table-column>
                  <el-table-column prop="customer_survey_time" align="center" label="评估时间">
                  </el-table-column>
                  <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                      <el-button size="mini" @click="kehudiaochabiaoxiangqing(scope.row)">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>

              </el-tab-pane>
            </el-tabs>
          </template>
        </el-tab-pane>
        <el-tab-pane label="附件">
          <template>
            <el-table :data="fujianshuzu" height="250" border style="width: 100%">
              <el-table-column align="center" prop="type" label="类型">
              </el-table-column>
              <el-table-column prop="description" label="姓名" align="center">
              </el-table-column>
              <el-table-column align="center" prop="mD5" label="mD5">
              </el-table-column>
              <el-table-column align="center">
                <template slot-scope="scope">
                  <el-button size="mini" @click="fujianhandleEdit(scope.$index, scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-tab-pane>
        <el-tab-pane label="交易">
          <template>
            <el-table :data="jiaoyitabledata" border style="width: 100%">
              <el-table-column prop="tenant_name" label="商家" align="center"></el-table-column>
              <el-table-column prop="store_name" label="店铺" align="center"></el-table-column>
              <el-table-column prop="store_exchange_no" label="单号" align="center">
              </el-table-column>
              <el-table-column label="客户" align="center">
                <template slot-scope="scope">
                  <span>
                    {{ scope.row.customer_name }}{{ scope.row.customer_id }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="买入" align="center">
                <template slot-scope="scope">
                  <span>{{ formatAmount(scope.row.buy_amount)
                    }}{{ scope.row.buy_currency_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="卖出" align="center">
                <template slot-scope="scope">
                  <span>{{ formatAmount(scope.row.sale_amount)
                    }}{{ scope.row.sale_currency_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="汇率" align="center">
                <template slot-scope="scope">
                  <span>{{ formatAmount(scope.row.rate) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="汇率倒数" align="center">
                <template slot-scope="scope">
                  <span>{{ formatAmount(scope.row.rate_reciprocal) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="exchange_type_name" label="交易类型" align="center" width="150">
              </el-table-column>
              <el-table-column prop="create_time" label="时间" width="110" align="center">
              </el-table-column>
              <el-table-column prop="status_name" label="状态" width="100" align="center">
              </el-table-column>
              <el-table-column width="120" align="center">
                <template slot-scope="scope">
                  <div>
                    <el-button type="warning" size="mini" @click="showEditDialog(scope.row)" icon="el-icon-edit">详情
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination :page-size="jiaoyipageinfo.pageSize" layout="prev, pager, next" :total="jiaoyipageinfo.total"
              @current-change="jiaoyihandleCurrentChange">
            </el-pagination>
          </template>
        </el-tab-pane>
        <el-tab-pane label="U地址">
          <el-row>
            <el-col :span="8">
              <el-select v-model="UdizhiPoper" placeholder="请选择地区">
                <el-option v-for="item in diqudizhiInfo" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8">
              <el-select v-model="UleixingPoper" placeholder="请选择客户类型">
                <el-option v-for="item in CoustormType" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-col>

            <el-col :span="8">
              <el-button type="primary" @click="addUD">添加地址</el-button>
            </el-col>

          </el-row>
          <template>
            <el-table :data="dizhidata" border style="width: 100%" ref="UdizhiTable">
              <el-table-column type="selection" width="80"></el-table-column>
              <el-table-column label="是否内部地址" prop="isInner">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.isInner" active-color="#13ce66" inactive-color="#ff4949">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="是否启用" prop="isEnable">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.isEnable" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="币种" prop="bank_code" />
              <el-table-column label="链/协议" prop="network" />
              <el-table-column label="地址" prop="qr_code" />
            </el-table>
          </template>
        </el-tab-pane>
      </el-tabs>
    </el-card>


    <!-- 身份证弹窗 -->
    <el-dialog title="证件信息" :visible.sync="zhengjianshow" width="40%">
      <el-button type="primary" v-print="'#zhengjian'">打印</el-button>
      <div id="zhengjian">
        <!-- 显示选中的证件详细信息 -->
        <div v-if="selectedCredential"
          style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: left; padding-top: 60px; padding-left: 60px">

          <!-- 普通字段展示 -->
          <div v-for="(field, index) in fields" :key="index"
            style="display: flex;flex-direction: column; justify-content: center; align-items: flex-start;">
            <p>{{ field.label }}:{{ convertFieldValue(field) }}</p>
          </div>
          <!-- 显示图片 -->
          <div style="margin-top: 10px;" v-if="imageUrls.length > 0">
            <div v-for="(image, index) in imageUrls" :key="index">
              <div style="width: 358px; height: 225px;margin-top: 20px;">
                <el-image :src="image.url" :preview-src-list="imageUrls.map(item => item.url)"></el-image>
              </div>

            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 附件弹窗 -->
    <el-dialog title="附件" :visible.sync="fujianshow" width="60%" height="60%">
      <el-button v-show="fujianleixing == 'image/jpeg'" type="primary" v-print="'#fujiandayin'">打印</el-button>
      <div id="fujiandayin">
        <!-- <img v-show="fujianleixing == 'image/jpeg'" :src="fujianurl" alt="xxx" style="width: 100%; height: 100%;"> -->
        <el-image v-show="fujianleixing == 'image/jpeg'" style="width: 100%; height: 100%" :src="fujianurl"
          :preview-src-list="[fujianurl]" fit="contain"></el-image>
        <iframe v-show="fujianleixing == 'application/pdf'" :src="fujianurl" id="pdfFrame" width="100%" height="
          600px"></iframe>
      </div>
    </el-dialog>
    <!-- 风险评估弹窗 -->
    <el-dialog title="风险评估" width="60%" :visible.sync="fengxianpinggushow">
      <el-button type="primary" v-print="'#fenxianpinggu'">打印</el-button>
      <div id="fenxianpinggu">
        <div class="header" v-if="dengxianpinggutabel2.length">
          <!-- <p v-html="fengxianpinggutable.header_remark"></p> -->
          <p>{{ fengxianpinggutable.header_remark }}</p>

        </div>
        <!-- <div style="display: flex; justify-content: space-between;">
                  <span>编号:</span>
                  <span>日期:</span>
                </div> -->
        <div class="fxpgcontainer">
          <table v-if="dengxianpinggutabel2.length" class="risk-assessment-table">
            <tr>
              <th></th>
              <th></th>
              <th>低风险</th>
              <th>中风险</th>
              <th>高风险</th>
            </tr>
            <tr>
              <td>风险因素</td>
              <td>评分因素</td>
              <td>1分项目</td>
              <td>2分项目</td>
              <td>3分项目</td>
            </tr>
            <tr>
              <td :id="dengxianpinggutabel2[0].survey_item_id" rowspan="3">{{ dengxianpinggutabel2[0].survey_item_name
                }}
              </td>
              <td :id="dengxianpinggutabel2[1].survey_item_id">{{ dengxianpinggutabel2[1].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[2].survey_item_id">{{ dengxianpinggutabel2[2].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[3].survey_item_id">{{ dengxianpinggutabel2[3].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[4].survey_item_id">{{ dengxianpinggutabel2[4].survey_item_name }}
              </td>
            </tr>

            <tr>
              <td :id="dengxianpinggutabel2[5].survey_item_id">{{ dengxianpinggutabel2[5].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[6].survey_item_id">{{ dengxianpinggutabel2[6].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[7].survey_item_id">{{ dengxianpinggutabel2[7].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[8].survey_item_id">{{ dengxianpinggutabel2[8].survey_item_name }}
              </td>
            </tr>
            <tr>
              <td :id="dengxianpinggutabel2[9].survey_item_id">{{ dengxianpinggutabel2[9].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[10].survey_item_id">{{ dengxianpinggutabel2[10].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[11].survey_item_id">{{ dengxianpinggutabel2[11].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[12].survey_item_id">{{ dengxianpinggutabel2[12].survey_item_name }}
              </td>
            </tr>
            <tr>
              <td :id="dengxianpinggutabel2[13].survey_item_id" rowspan="2">
                {{ dengxianpinggutabel2[13].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[14].survey_item_id">{{ dengxianpinggutabel2[14].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[15].survey_item_id">{{ dengxianpinggutabel2[15].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[16].survey_item_id">{{ dengxianpinggutabel2[16].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[17].survey_item_id">{{ dengxianpinggutabel2[17].survey_item_name }}
              </td>
            </tr>
            <tr>
              <td :id="dengxianpinggutabel2[18].survey_item_id">{{ dengxianpinggutabel2[18].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[19].survey_item_id">{{ dengxianpinggutabel2[19].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[20].survey_item_id">{{ dengxianpinggutabel2[20].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[21].survey_item_id">{{ dengxianpinggutabel2[21].survey_item_name }}
              </td>

            </tr>
            <tr>
              <td :id="dengxianpinggutabel2[22].survey_item_id">{{ dengxianpinggutabel2[22].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[23].survey_item_id">{{ dengxianpinggutabel2[23].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[24].survey_item_id">{{ dengxianpinggutabel2[24].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[25].survey_item_id">{{ dengxianpinggutabel2[25].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[26].survey_item_id">{{ dengxianpinggutabel2[26].survey_item_name }}
              </td>
            </tr>
            <tr>
              <td :id="dengxianpinggutabel2[27].survey_item_id">{{ dengxianpinggutabel2[27].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[28].survey_item_id">{{ dengxianpinggutabel2[28].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[29].survey_item_id">{{ dengxianpinggutabel2[29].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[30].survey_item_id">{{ dengxianpinggutabel2[30].survey_item_name }}
              </td>
              <td :id="dengxianpinggutabel2[31].survey_item_id">{{ dengxianpinggutabel2[31].survey_item_name }}
              </td>

            </tr>
            <tr>
              <td>總得分</td>
              <td>{{ zongdefen }}</td>
              <td colspan="3">總得分是將客戶各類風險度的得分相加</td>
            </tr>
          </table>
          <div class="footer">
            <p>{{ fengxianpinggutable.foot_remark }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 客户调查表弹窗 -->
    <el-dialog title="客户调查表" :visible.sync="kehudiaochabiaoshow">
      <el-button type="primary" v-print="'#kehudiaochabiao'">打印</el-button>
      <div id="kehudiaochabiao">
        <template>
          <div class="customerSurveryForm">
            <div style="display: flex; flex-direction: column; ">
              <h2 style="text-align: center;">客戶盡職審查</h2>
              <!-- <div style="display: flex; justify-content: space-between;">
                        <span>編號:</span> <span>日期:2018-09-03</span>
                      </div> -->
              <table v-if="tableData2.length" class="kehudiaochabiao">
                <tr>
                  <th></th>
                  <th>申請人資料(風險評估項目1-4)</th>
                  <th>匯款人資料(與申請人不同)(風險評估項目3-4)</th>
                  <th>收款人資料(風險評估項目5)</th>
                </tr>
                <tr v-for="(item, index) in tableData2" :key="index">
                  <td>{{ item.survey_item_name }}</td>
                  <td v-for="(child, childindex) in item.children" :key="childindex">{{ child.survey_item_value }}</td>
                </tr>
              </table>
              <div>
                聲明：{{ kehudiaochabiaodata2.foot_remark }}
              </div>
              <!-- <div style="display: flex; justify-content: space-between;">
                        <span style="margin-left: 10px;">簽名:</span> <span style="margin-right: 10px;">日期:</span>
                      </div> -->
            </div>
          </div>
        </template>
      </div>
    </el-dialog>
    <!-- 道琼斯弹窗1 -->
    <el-dialog title="道琼斯调查表" :visible.sync="daoqiongsidataShow1">
      <el-button type="primary" v-print="'#daoqiongsidata1'">打印</el-button>
      <div id="daoqiongsidata1">
        <div style="text-align: left;">
          <img src="../../../public/img/微信图片_20241126163306.png" alt="login" style="width: 200px; height: 50px;">
        </div>
        <div>
          <div style="border: 1px solid black; border-right: none; display: flex; flex-direction: column; width: 100%;">
            <p style="display: flex; "> <span
                style="width: 27%; color: black; font-weight: bold; text-align: left;">所使用的搜索条件</span> : <span></span>
            </p>
            <p style="display: flex; "> <span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">名称</span><span
                style="color: orangered; font-weight: 600;">:{{ daoqiongsihangxiangqing.searchValue }}</span></p>
            <p style="display: flex; "><span
                style="width: 27%; color: black; font-weight: bold; text-align: left;">内容集</span><span
                style="color: black; font-weight: 600;">:Watchlist, State Owned Companies , Adverse Media
                Entities</span>
            </p>
            <p style="display: flex; "><span style="width: 27%;color: black; font-weight: bold; text-align: left;">
                搜索</span><span>:人物,机构 </span></p>
            <p style="display: flex; "><span style="width: 27%;color: black; font-weight: bold; text-align: left;">
                搜索参数</span><span>:无</span></p>
            <p style="display: flex; "><span style="width: 27%; color: black; font-weight: bold;text-align: left;"> ID
                号码种类</span><span>:无</span></p>
            <p style="display: flex; "><span style="width: 27%;color: black; font-weight: bold; text-align: left;">ID
                号码</span><span>:无 </span></p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">搜索类型</span><span>:精确 </span></p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">出生日期</span><span
                style="color: black; font-weight: 600;"> :{{ daoqiongsihangxiangqing.searchBri }}</span></p>
            <p style="display: flex; "><span
                style="width: 27%; color: black; font-weight: bold;text-align: left;">完全符合的日期</span><span>:是</span></p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">地区</span><span>: 所有地区</span></p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">国家/地区类型</span><span>:所有国家/地区类型</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">政治公众人物类别</span><span>:
                所有政治人物类别</span>
            </p>
            <p style="display: flex; "><span style="width: 27%;color: black; font-weight: bold; text-align: left;">排除
                ADSR</span><span>:否</span></p>
            <p style="display: flex; "><span
                style="width: 27%; color: black; font-weight: bold;text-align: left;">需特别关注的类别</span><span>:所有需特别关注的类别</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">制裁名单</span>:<span>所有制裁名单</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">排除已停止更新之制裁名单</span><span>:否</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%; color: black; font-weight: bold;text-align: left;">其他官方名单</span><span>:所有其他官方名单</span>
            </p>
            <p style="display: flex; "><span style="width: 27%;color: black; font-weight: bold; text-align: left;">
                排除已停止更新之其他官方名单</span><span>:否</span></p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">其他禁令黑名单</span><span>:所有其他禁令黑名单</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">排除已停止更新之其他禁令黑名单</span><span>:否</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">国有股权</span><span>:所有国有持股程度</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">排除未知股权</span><span>:是</span></p>
            <p style="display: flex; "> <span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">Adverse
                Media Entities类别</span><span style="color: black; font-weight: 600;">:所有Adverse Media Entities类别</span>
            </p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">获得的搜索结果</span><span
                style="color: black; font-weight: 600;">:{{ daoqiongsidayindata.length }}个条目</span></p>
            <p style="display: flex; "><span
                style="width: 27%;color: black; font-weight: bold; text-align: left;">搜索日期</span><span
                style="color: black; font-weight: 600;">:{{ daoqiongsihangxiangqing.StartTime }}</span></p>
            <p style="display: flex; "><span
                style="width: 27%; color: black; font-weight: bold;text-align: left;">用户名称</span><span
                style="color: black; font-weight: 600;">:E001085902</span> </p>
          </div>
          <div v-if="daoqiongsidayindata.length >= 1"
            style="text-align: center; color: black; font-weight: bold; padding:  10px 0;">
            显示第0-{{ daoqiongsidayindata.length }}项结果，搜索结果总计:{{ daoqiongsidayindata.length }}
          </div>
          <div v-else style="text-align: center; color: black; font-weight: bold; padding:  10px 0;">
            显示第0项结果,搜索结果总计:0
          </div>
          <div>
            <div>
              <div style="background-color: darkgray; width: 100%; height:40px; display: flex;">
                <p style="width: 20%;"></p>
                <p style="width: 20%; text-align: left; color: black; font-weight: bold; line-height: 40px;">名称</p>
                <p style="width: 18%; text-align: left; color: black;font-weight: bold; line-height: 40px;">国家/地区</p>
                <p style="width: 24%; text-align: left; color: black; font-weight: bold; line-height: 40px;">标题</p>
                <p style="width: 10%; text-align: left; color: black; font-weight: bold; line-height: 40px;">子公司</p>
                <p style="width: 6%; line-height: 40px;">%</p>
              </div>
              <div class="old-table-daoqiongsi" v-for="(cItem, cIndex) in daoqiongsidayindata" :key="cIndex">
                <div class="old-daoqiongsi-td1">
                  <div v-for="(icon, index) in cItem.attributes.icon_hints" :key="index">
                    <img :src="imageData.oolPersonActive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('OOL-PERSON') && icon.status == 'Active'">
                    <img :src="imageData.pepActive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('PEP') && icon.status == 'Active'">
                    <img :src="imageData.siltActive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('SI-LT') && icon.status == 'Active'">
                    <img :src="imageData.siPersonActive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('SI-PERSON') && icon.status == 'Active'">
                    <img :src="imageData.msbActive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('MSB') && icon.status == 'Active'">
                    <img :src="imageData.racActive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('RCA') && icon.status == 'Active'">
                    <img :src="imageData.pepInactive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('PEP') && icon.status == 'Inactive'">
                    <img :src="imageData.msbInactive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('MSB') && icon.status == 'Inactive'">
                    <img :src="imageData.racInactive" style="width: 34px;height: 13px;margin-right: 5px;"
                      v-if="icon.icon_hint.includes('RCA') && icon.status == 'Inactive'">
                  </div>
                </div>
                <div class="old-daoqiongsi-td2" v-show="cItem.attributes && cItem.attributes.primary_name">
                  {{ cItem.attributes.primary_name }}</div>
                <div class="old-daoqiongsi-td3" v-show="cItem.attributes && cItem.attributes.country_territory_code">
                  {{ cItem.attributes.country_territory_code }}</div>
                <div class="old-daoqiongsi-td4">{{ cItem.attributes.title }}</div>
                <div class="old-daoqiongsi-td5">{{ cItem.attributes.is_subsidiary ? "" : "不可存取" }}</div>
                <div class="old-daoqiongsi-td6" v-show="cItem.attributes && cItem.attributes.score">
                  {{ Number(Number(cItem.attributes.score).toFixed(2)) * 100 }}</div>
              </div>

            </div>
            <div style="display: flex; justify-content: space-between; margin-top: 50px;">
              <div style="color: black; font-weight: bold;"> © 2024 Dow Jones & Company. All rights reserved.</div>
              <div><img style="height: 25px; width: 140px;" src="../../../public/img/20241126203811.png" alt=""
                  srcset="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 道琼斯弹窗2 -->
    <el-dialog title="道琼斯调查表" :visible.sync="daoqiongsidataShow2">
      <!-- 打印 -->
      <el-button type="primary" v-print="'#daoqiongsidata2'">打印</el-button>
      <div id="daoqiongsidata2"
        style="width: 794px;height: 1123px;padding-top: 70px; padding-left: 45px; padding-right: 45px;">
        <div style="width: 100%;border-bottom: 3px solid darkgray; text-align: left;">
          <img :src="imageData.logoImg" alt="login" style="height: 13px;">
        </div>
        <div style="width: 100%; border-bottom: 1px solid black; text-align: left;margin-top: 5px;">
          <p v-show="!daoqiongsihangxiangqing.idNumber" style="color: black; margin-bottom: 5px; font-size: 9px;">高级搜索摘要
          </p>
          <p v-show="daoqiongsihangxiangqing.idNumber" style="color: black; margin-bottom: 5px; font-size: 9px;">搜索摘要
          </p>
        </div>
        <div style="width: 100%; border-bottom: 1px solid gray;">
          <div style="display: flex; margin: 10px 0; align-items: center; ">
            <span style="font-size: 7px;color: darkgray;">搜索:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px; ">个人/机构</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray;">用户:</span><span
              style="margin-left: 10px; color: black; font-size: 8px; ">{{ daoqiongsihangxiangqing.dowjonesUserName
              }}</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray;">搜索日期:</span><span
              style="margin-left: 10px; color: black; font-size: 8px; ">{{
                daoqiongsihangxiangqing.StartTime }}</span>
          </div>
          <div style="text-align: left; margin: 5px 0;">
            <p style="font-size: 9px;color: darkgray;">所使用的搜索条件</p>
          </div>
        </div>
        <div style="width: 100%; border-bottom: 1px solid gray;">
          <div style="display: flex; margin: 10px 0; align-items: center; ">
            <span v-show="daoqiongsihangxiangqing.idNumber" style="font-size: 7px;color: darkgray;">ID类型:</span><span
              v-show="daoqiongsihangxiangqing.idNumber"
              style="margin-left: 10px; color: black; font-size: 8px;">身份证号码</span>
            <span v-show="daoqiongsihangxiangqing.idNumber"
              style="margin-left: 10px;font-size: 7px;color: darkgray; ">ID号码:</span><span
              v-show="daoqiongsihangxiangqing.idNumber" style="margin-left: 10px; color: black; font-size: 8px; ">{{
                daoqiongsihangxiangqing.idNumber }}</span>
            <span v-show="!daoqiongsihangxiangqing.idNumber" style="font-size: 7px;color: darkgray;">名称:</span><span
              v-show="!daoqiongsihangxiangqing.idNumber"
              style="margin-left: 10px; color: black; font-size: 8px;">{{ daoqiongsihangxiangqing.searchValue }}</span>
            <span style="margin-left: 10px;font-size: 7px;color: darkgray;">搜索类型:</span><span
              v-show="!daoqiongsihangxiangqing.idNumber"
              style="margin-left: 10px; color: black; font-size: 8px;">精确</span>
            <span v-show="daoqiongsihangxiangqing.idNumber"
              style="margin-left: 10px; color: black; font-size: 8px;">广泛</span>
            <span v-show="!daoqiongsihangxiangqing.idNumber && daoqiongsihangxiangqing.searchbring"
              style="margin-left: 10px;font-size: 7px;color: darkgray;">出生日期:</span><span
              v-show="!daoqiongsihangxiangqing.idNumber && daoqiongsihangxiangqing.searchbring"
              style="margin-left: 10px; color: black; font-size: 8px;">{{ daoqiongsihangxiangqing.searchbring }}</span>

            <span style="margin-left: 10px;font-size: 7px; color: darkgray;">档案注解:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">排除</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray; ">职位名称:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">排除</span>
          </div>
          <div style="display: flex; margin: 10px 0; align-items: center; ">
            <span style="font-size: 7px;color: darkgray; ">国家/地区:</span><span
              style="margin-left: 10px; color: black; font-size: 8px; ">所有</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray; ">国家类型:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
          </div>
          <div style="text-align: left; margin:10px 0 5px 0;">
            <p style="font-size: 9px; color: darkgrey; ">内容集</p>
          </div>
        </div>
        <div>
          <div style="display: flex; margin: 10px 0; align-items: center; ">
            <span style="font-size: 7px; color: darkgray ">WATCHLIST:</span><span style="margin-left: 10px;"></span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray">政治公众人物:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray">包括ADSR:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">是</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgrey ">需特别关注类别:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgrey;">制裁名单:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray; ">其他官方名单:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
            <span style="margin-left: 10px;font-size: 7px; color: darkgray;">其他禁令黑名单:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
          </div>
          <div style="display: flex; margin:15px 0; align-items: center; ">
            <span style="font-size: 7px;color: darkgray; ">军事最终用户相关实体:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px; ">所有</span>
          </div>
          <div style="display: flex; margin:15px 0; align-items: center; ">
            <span style="font-size: 7px;color: darkgray; ">国有公司:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px; ">所有</span>
            <span style="margin-left: 10px; font-size: 7px; color: darkgrey; ">排除未知股权:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px; ">是</span>
          </div>
          <div style="display: flex; margin:15px 0;  align-items: center; ">
            <span style="font-size: 7px;color: darkgrey; ">ADVERSE MEDIA:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px; ">包括</span>
            <span style="margin-left: 10px; font-size: 7px;color: darkgray; ">ADVERSE MEDIA类别:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
          </div>
          <div style="display: flex; margin:15px 0; align-items: center; ">
            <span style="font-size: 7px;color: darkgray; ">位置:</span><span
              style="margin-left: 10px; color: black; font-size: 8px;">包括</span>
            <span style="margin-left: 10px;  font-size: 7px;color: darkgrey; ">位置类别:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
          </div>
          <div style="display: flex; margin:15px 0; align-items: center; ">
            <span style="font-size: 7px; color: darkgray;">高风险因素:</span><span
              style="margin-left: 10px; color: black;  font-size: 8px;">包括</span>
            <span style="margin-left: 10px; font-size: 7px;color: darkgray; ">大麻相关业务:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
            <span style="margin-left: 10px; font-size: 7px; color: darkgray; ">货币服务业务:</span> <span
              style="margin-left: 10px; color: black; font-size: 8px;">所有</span>
          </div>
        </div>
        <div style="width: 100%; border-bottom: 1px solid gainsboro; text-align: left;margin-top: 15px;">
          <p v-if="daoqiongsidayindata.length >= 1"
            style="color: black; font-size: 10px; font-weight: bold; margin-bottom: 5px;">{{ daoqiongsidayindata.length
            }}搜索结果 1 {{ -
              daoqiongsidayindata.length
            }}</p>
          <p v-else style="color: black; font-size: 9px; font-weight: 600; margin-bottom: 5px;">0 搜索结果0 - 0 </p>
        </div>

        <div style=" border-bottom: 1px solid gainsboro;  width: 100%; height:19px; display: flex;">
          <p style="width: 12%;"></p>
          <p style="width: 11%; text-align: left; color: black; font-weight: bold;  font-size: 9px;line-height: 20px;">
            档案ID
          </p>
          <p style="width: 11%; text-align: left; color: black;font-weight: bold; font-size: 9px;line-height: 20px;">名称
          </p>
          <p style="width: 11%; text-align: left; color: black; font-weight: bold; font-size: 9px;line-height: 20px;">性别
          </p>
          <p style="width: 11%; text-align: left; color: black; font-weight: bold; font-size: 9px;line-height: 20px;">
            出生日期
          </p>
          <p style="width: 11%; text-align: left; color: black;font-weight: bold;  font-size: 9px;line-height: 20px;">
            国家/地区
          </p>
          <p style="width: 16%; text-align: left; color: black; font-weight: bold; font-size: 9px;line-height: 20px;">详情
          </p>
          <p style="width: 11%; text-align: left; color: black;font-weight: bold;  font-size: 9px;line-height: 20px;">
            子公司
          </p>
          <p style="width: 6%; text-align: left; color: black; font-weight: bold; font-size: 9px;line-height: 20px;">%
          </p>
        </div>

        <div class="new-table-daoqiongsi" v-for="(cItem, cIndex) in daoqiongsidayindata" :key="cIndex">
          <div class="new-daoqiongsi-td1">
            <div v-for="(icon, index) in cItem.attributes.icon_hints" :key="index">
              <img :src="imageData.oolPersonActive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('OOL-PERSON') && icon.status == 'Active'">
              <img :src="imageData.pepActive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('PEP') && icon.status == 'Active'">
              <img :src="imageData.siltActive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('SI-LT') && icon.status == 'Active'">
              <img :src="imageData.siPersonActive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('SI-PERSON') && icon.status == 'Active'">
              <img :src="imageData.msbActive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('MSB') && icon.status == 'Active'">
              <img :src="imageData.racActive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('RCA') && icon.status == 'Active'">
              <img :src="imageData.pepInactive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('PEP') && icon.status == 'Inactive'">
              <img :src="imageData.msbInactive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('MSB') && icon.status == 'Inactive'">
              <img :src="imageData.racInactive" style="width: 30px;height: 10px;margin-right: 5px;"
                v-if="icon.icon_hint.includes('RCA') && icon.status == 'Inactive'">
            </div>
          </div>
          <div class="new-daoqiongsi-td2">{{ cItem.id }}</div>
          <div class="new-daoqiongsi-td3">{{ cItem.attributes.primary_name }}</div>
          <div class="new-daoqiongsi-td4">{{ cItem.attributes.gender }}</div>
          <div class="new-daoqiongsi-td5">
            <div>
              <span
                v-show="cItem.attributes && cItem.attributes.matched_criteria.date_of_birth && cItem.attributes.matched_criteria.date_of_birth[0] && cItem.attributes.matched_criteria.date_of_birth.year">{{
                  cItem.attributes.matched_criteria.date_of_birth.year + "年" }} </span>
              <span
                v-show="cItem.attributes && cItem.attributes.matched_criteria.date_of_birth && cItem.attributes.matched_criteria.date_of_birth[0] && cItem.attributes.matched_criteria.date_of_birth.month">{{
                  cItem.attributes.matched_criteria.date_of_birth.month + "月" }} </span>
              <span
                v-show="cItem.attributes && cItem.attributes.matched_criteria.date_of_birth && cItem.attributes.matched_criteria.date_of_birth[0] && cItem.attributes.matched_criteria.date_of_birth.day">{{
                  cItem.attributes.matched_criteria.date_of_birth.day + "日" }} </span>
            </div>
          </div>
          <div class="new-daoqiongsi-td6">{{ cItem.attributes.country_territory_name }}</div>
          <div class="new-daoqiongsi-td7">{{ cItem.attributes.title }}</div>
          <div class="new-daoqiongsi-td8">{{ cItem.attributes.is_subsidiary ? "" : "不可存取" }}</div>
          <div class="new-daoqiongsi-td9">{{ Number(Number(cItem.attributes.score).toFixed(2)) * 100 }}</div>

        </div>

        <p v-if="daoqiongsidayindata.length < 1"
          style="font-size: 10px;text-align: left; color: black; padding: 10px 0 20px 0 ">无搜索结果。</p>


        <p style=" color: black;font-size: 10px; font-weight: 500; margin: 10px 0 9px 0; text-align: left;">免责声明</p>
        <div
          style="text-align: left; height: 270px; display: flex; flex-direction: column; justify-content: space-around; ">

          <div style="font-size:7.9px; letter-spacing: 1.1px; /* 增加字母间距 */font-family: 'SimSun', '宋体', serif;
                      word-spacing: 1px;  /* 增加字间距 */ ">本网站上的信息完全来自可公开获取的来源，并提供给我们的订阅者以帮助他们进行决策，道琼斯（Dow jones）不保证"Risk
            &
            Compliance"服务收录每个个人或公司及所有新闻事件的完整或准确的档案。信息来源并非囊括所有的信息渠道，数据库在某件特定事件上可能无法反映其全部的发展。道琼斯并不暗示对数据库所收录的人员和机构有任何负面推论
          </div>
          <p style="font-size:8px;letter-spacing: 1.1px;word-spacing: 1px;  ">道琼斯提醒用户注意误报—
            —拥有相似或相同的名称的个人/机构可能与数据库信息没有关联。并建议用户进行进一步审查，以消除所有误报。</p>
          <p style="font-size:8px;letter-spacing: 1.1px;word-spacing: 1px; ">
            道琼斯建议用户在决策之前，结合档案信息与原始信息源的详细信息，在数据库外进行进一步的调研。
          </p>
          <p style="font-size:8px;letter-spacing: 1.1px;word-spacing: 1px; ">
            T档案列出的链接与档案主体的关联可能是多样的，用户不应仅基于此类链接做出负面推论。例如，其他官方名单档案里的Associate链接，仅表示该链接的人员或机构曾与档案主体列于同一名单上。如果一位个人被归类为需特别关注的人士（SIP）的
            亲属或具密切关系人员(RCA)，并不意味着该人物本身应该被视为需特别关注的人士（SIP）。</p>
          <p style="font-size:8px;letter-spacing: 1.1px;word-spacing: 1px; ">在制栽控制和所有权定义里表明，虽然有些机构可能并未被点名制裁，但是它们特合Dow
            Jones
            Risk &
            Compliance制裁控制和所有权定义，这不一定表明该机构必须被视为受制栽实体，某些机构的所有板和控制权情况可能要求其被视为受制裁实体</p>
          <p style="font-size:8px;letter-spacing: 1.1px;word-spacing: 1px;">Dow Jones Risk & Compliance
            信息属于机密信息。此信息仅适用于Dow
            Jones Risk & Compliance服务的订阅者依据其适用的主协议和订单使用。</p>

          <div style="text-align: left;">
            <img :src="imageData.loginbottom" alt="" style="width: 150px;height: 13px;">
            <p style="font-size: 9px;">© 2024 Dow Jones & Company, Inc. 版权所有。</p>
            <p style="font-size: 9px; margin-left: 2px;"> 人物/机构 搜索支持 <u>Linguistic Search Solutions</u></p>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 交易弹窗 -->
    <el-dialog title="详细信息" width="1250px" :visible.sync="detailsWindowl" center>
      <div class="detailsPop">
        <div>
          <div class="detailsList">
            <div class="item-info">
              <div class="item-title">
                <div>{{ detailsInfo.store_exchange_no }}</div>
                <div>
                  客户：{{ detailsInfo.customer_name }}({{
                    detailsInfo.customer_no
                  }})
                </div>
              </div>
              <div class="item-content">
                <div>
                  <div>
                    买入：{{ Number(detailsInfo.buy_amount)
                    }}{{ detailsInfo.buy_currency_name }}
                  </div>
                  <div>
                    卖出：{{ Number(detailsInfo.sale_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>买汇价：{{ detailsInfo.rate * 1 }}</div>
                </div>
                <div v-if="
                  detailsInfo.exchange_type != 'Cash2Cash' &&
                  detailsInfo.exchange_type != 'Surplus2Cash'
                ">
                  <div>
                    手续费：{{ Number(detailsInfo.fee_amount)
                    }}{{ detailsInfo.fee_currency_name }}
                  </div>
                  <div>
                    自带：{{ Number(detailsInfo.bring_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    自取：{{ Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
                <div v-if="
                  detailsInfo.exchange_type != 'Cash2Cash' &&
                  detailsInfo.exchange_type != 'Surplus2Cash'
                ">
                  <div>
                    应付：{{
                      Number(detailsInfo.sale_amount) +
                      Number(detailsInfo.bring_amount) -
                      Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    待付：{{ Number(detailsInfo.toBePaidAmount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
              </div>
              <div v-if="
                detailsInfo.exchange_type == 'Cash2Cash' ||
                detailsInfo.exchange_type == 'Surplus2Cash'
              ">
                <div></div>
                <div>状态：{{ detailsInfo.statusString }}</div>
              </div>
            </div>
            <template>
              <div class="header-title" v-if="
                detailsInfo.exchange_type != 'Cash2Cash' &&
                detailsInfo.exchange_type != 'Surplus2Cash'
              ">
                <div v-for="(item, index) in detailsInfo.details" :key="'details' + index" class="item-ul">
                  <div class="line"></div>
                  <div class="item-li">
                    <div v-if="
                      detailsInfo.details && detailsInfo.details.length > 1
                    ">
                      第{{ index + 1 }}笔
                    </div>
                  </div>
                  <div class="item-li">
                    <div class="item-li">
                      <div>
                        收款人：{{ detailsInfo.customer_name }}({{
                          detailsInfo.customer_no
                        }})
                      </div>
                      <div v-for="(cItem, cIndex) in item.cards" :key="'cItem' + cIndex">
                        <div v-if="cItem.bank_card_type_code == 'BankCard'">
                          {{ cItem.holder_name }}
                          {{ cItem.bank_class_name }} ({{
                            cItem.bank_card_number
                          }})
                        </div>
                        <div v-if="
                          cItem.bank_card_type_code == 'WX' ||
                          cItem.bank_card_type_code == 'Alipay'
                        " class="paymentCode">
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">好友码：</div>
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">付款码：</div>
                          </div>
                        </div>
                        <div v-if="cItem.bank_card_type_code == 'Virtual'" class="paymentCode">
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div>协议：{{ cItem.network }}</div>
                          &nbsp; &nbsp;
                          <div>地址：{{ cItem.qr_code }}</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      金额：{{ Number(item.amount) }}&nbsp; &nbsp;{{
                        detailsInfo.sale_currency_code
                      }}
                    </div>
                  </div>

                  <div v-if="item.pamentDetails && item.pamentDetails.length > 0">
                    <div v-for="(detail, dIndex) in item.pamentDetails" :key="'detail' + dIndex" class="itembox">
                      <div class="item-li">
                        <div v-if="!detail.payment_customer_name">
                          付款人：{{ detail.payment_client_id || "" }}
                          {{ detail.payment_tenant_name }}
                          {{ detail.payment_store_name }}
                          <text v-if="detail.bank_card_type_code == 'BankCard'">({{ detail.bank_card_number }})</text>
                        </div>
                        <div v-else>
                          付款人：{{ detail.payment_customer_name || "" }}(
                          {{ detail.payment_customer_id }})
                          <text v-if="detail.bank_card_type_code == 'BankCard'">({{ detail.bank_card_number }})</text>
                        </div>
                        <div>
                          金额：{{ Number(detail.amount)
                          }}{{ detail.currency_name }}
                        </div>
                      </div>
                      <div class="item-li">
                        <div>时间：{{ detail.payment_time }}</div>
                        <div>状态：{{ detail.statusName }}</div>
                      </div>
                      <div class="item-li attachBtn">
                        <div class="attachment" v-show="detail.attachments">
                          <AttachmentPreview :uploaded="detail.attachments" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>


</template>
<script>
import moment from 'moment';
import { imageData } from '@/utils/imageData.js';
import axios from "axios";
export default {
  mounted() {
    this.imageData = imageData;
    if (this.$route.query.no) {
      this.formLabelAlign.no = this.$route.query.no;
      this._customernamedate();
    } else {
      this.formLabelAlign.no = '';
    }
    // this.getLianxifangshizidain();
    this._getGeneralStructureType(); // 获取证件类型

  },
  data() {
    return {
      UleixingPoper: "guest",
      UdizhiPoper: "HongKong",
      diqudizhiInfo: [
        {
          name: '香港',
          value: 'HongKong',
        },
        {
          name: '澳门',
          value: 'Macao',
        },
        {
          name: '新加坡',
          value: 'Singapore',
        },
      ],//地区地址信息
      CoustormType: [
        {
          name: '客人',
          value: 'guest',
        },
        {
          name: '供应商',
          value: 'supplier',
        },
        {
          name: '商家',
          value: 'business',
        },

      ],//客人类型
      dizhidata: [],//地址信息
      jiaoyitabledata: [],//交易记录tabel
      detailsWindowl: false,//交易详情弹窗显示
      detailsInfo: {},//交易详情
      // ---------
      daoqiongsibiaogezhongshuju: [], //总数居
      showPagination: false,
      daoqiongsipage: { index: 1, size: 10, total: 0 },
      daoqiongsihangxiangqing: [], //道琼斯调查表行数据
      daoqiongsidayindata: [], //道琼斯调查表打印数据
      imageData: {}, //图片数据
      daoqiongsidataShow1: false, //道琼斯tabel弹窗显示
      daoqiongsidataShow2: false, //道琼斯tabel弹窗显示
      daoqiongsidata: [], //道琼斯tabel数据
      // --------------
      tableData2: [],
      kehudiaochabiaodata2: [], //客户调查表数据 items
      kehudiaochabiaodatafooter: '', //客户调查表数据 footer 底部
      kehudiaochabiaodata1: [], //客户调查表数据
      kehudiaochabiaoshow: false, //客户调查表弹窗显示
      // ---------------
      zongdefen: 0,//风险评估总得分
      fengxianpinggushow: false, //风险评估弹窗显示

      fengxianpinggublue: [], //风险评估表变蓝色

      fengxianpinggutable: {}, //风险评估表的结构
      dengxianpinggutabel2: [], //风险评估表数据

      fengxianpinggudata: [], //风险评估表数据
      fujianshow: false, // 附件展示弹窗
      fujianleixing: '', // 附件类型
      fujianurl: '',
      fujianshuzu: [], // 附件数组
      zhengjianshow: false,//身份证弹窗
      activeName: 'first',
      formLabelAlign: {
        no: '',
      },
      credentiales: [],
      selectedCredential: null, // 客户档案
      generalStructureTypes: [], // 证件类型字典
      kehujibenxixin: {}, // 客户基本信息
      fields: [],  // 普通字段
      imageUrls: [],  // 存储图片的url
      lianxifangshi: {},  // 联系信息
      lianxifangshizidain: [], // 联系方式字典
      jiaoyipageinfo: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 0,
        total: 0
      }, //交易记录分页信息

    }
  },
  methods: {
    handleEnter() {


      // 在这里编写按下回车键后要执行的逻辑，比如调用_customernamedate方法等
      this._customernamedate();
    },
    // --------------------------------------
    // 公共事件
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    // --------------------------------------------------------------------------------
    // tab栏切换事件
    fenxianhandleClick(tab) {
      if (this.formLabelAlign.no == '') return
      if (tab.label == '风险') {
        this.daoqiongsipage = { index: 1, size: 10, total: 0 };
        this._getdaoqiongsi();// 获取道琼斯表
        this._getfengxianpinggu(); // 获取客户风险评估表
        this._kehudiaochabiao(); // 获取客户调查表
      } else if (tab.label == '交易') {
        this.jiaoyipageinfo = { pageIndex: 1, pageSize: 10, pageCount: 0, total: 0 };
        this._getjiaoyijilu(); // 获取交易记录
      } else if (tab.label == 'U地址') {
        this._customernamedate(); // 获取客户档案
        this._getUD(); // 获取客户档案
      }
    },

    // --------------------------------------------------------------------------------
    // 添加u地址
    addUD() {
      let selectedRows = this.getSelectedRows();
      let uddata = selectedRows.map(item => (

        {
          area: this.UdizhiPoper,
          type: this.UleixingPoper,
          currency: item.bank_code,
          chain: item.network,
          address: item.qr_code,
          isEnable: item.isEnable,
          isInner: item.isInner,
          archivesNo: this.kehujibenxixin.no,
          archivesName: this.kehujibenxixin.name,
          alisa: this.kehujibenxixin.name,
        }

      ));
      console.log(uddata, "addUD");

      this.addUDdizhi(uddata);
      // this.$refs.UdizhiTable.clearSelection();
    },
    // 添加U地址
    async addUDdizhi(uddata) {

      try {
        // 生成一个16位的随机整数
        const min = Math.pow(2, 15); // 2^15
        const max = Math.pow(2, 16) - 1; // 2^16 - 1
        let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          "trace-id": randomNum,
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/BatchAddOrUpdateTracking",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/BatchAddOrUpdateTracking",
          uddata,
          { headers }
        );


        // 检查响应数据是否存在
        if (response.code == 0) {
          // 处理响应数据
          this.$message({ message: "添加成功", type: "success" });
        } else {

          this.$message.error(response.message);
        }
      } catch (error) {

        console.error("Error fetching USDT balance:", error);
        this.$message.error("添加失败");
      }
    },
    // 获取单选选中的数据
    getSelectedRows() {
      const selectedRows = this.$refs.UdizhiTable.selection;
      return selectedRows;
    },
    // 获取U地址
    async _getUD() {
      const { data: res } = await this.$http.get("/api/CustomerBankCard/GetVirtual", {
        params: {
          customer: this.formLabelAlign.no,
        }
      })

      console.log(res, "_getUD");
      //  往res.data中添加一个字段，用于标记是否选中
      if (res.code !== 1) return this.$message.error(res.message);
      if(res.data && res.data.length > 0){

     
      res.data.forEach(item => {

        item.isEnable = true;
        item.isInner = false;
      });

    }
      this.dizhidata = res.data;
    },

    // --------------------------------------------------------------------------------
    // 获取交易详情
    async showEditDialog(row) {

      const { data: res } = await this.$http.get("StoreExchange/GetDetail", {
        params: {
          ExchangeID: row.store_exchange_id,
        },
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.toBePaidAmount = 0;
      if (res.data && res.data.details && res.data.details.length > 0) {
        res.data.details.forEach((item) => {
          res.data.toBePaidAmount += Number(item.wait_amount);
          if (item.pamentDetails && item.pamentDetails.length > 0) {
            item.pamentDetails.forEach((cItem) => {
              if (cItem.status == "agree") {
                cItem.statusName = "已同意收款";
              } else if (cItem.status == "wait") {
                cItem.statusName = "待确认收款";
              } else {
                cItem.statusName = "已拒绝收款";
              }
              cItem.uploadPath = [];
              if (cItem.attachments && cItem.attachments.length > 0) {
                cItem.attachments.forEach((attach) => {
                  cItem.uploadPath.push(attach.file_key);
                });
              }
            });
          }
        });
      }
      this.detailsInfo = res.data;
      this.detailsWindowl = true;
    },


    //  交易记录
    _getjiaoyijilu() {
      this.$http.get("/StoreExchange/CustomerStoreExhcnageQuery", {
        params: {
          customer: this.formLabelAlign.no,
          pageIndex: this.jiaoyipageinfo.pageIndex,
          pageSize: this.jiaoyipageinfo.pageSize,
        }
      }).then((res) => {
        if (res.data.code !== 1) return this.$message.error(res.data.message);

        this.jiaoyitabledata = res.data.data;
        this.jiaoyipageinfo = res.data.pageInfo;
        console.log(this.jiaoyitabledata);


      });
    },
    //  交易分页
    jiaoyihandleCurrentChange(e) {
      this.jiaoyipageinfo.pageIndex = e;
      this._getjiaoyijilu();
    },
    // 道琼斯
    // --------------------------------------------------------------------------------

    fengxianpingguxiangqing(e) {
      this.daoqiongsihangxiangqing = e
      console.log(this.daoqiongsihangxiangqing, "行数据");
      let data = JSON.parse(e.result);
      console.log(data, "数据");


      if (e.startTime > 1718380801) {
        this.daoqiongsidayindata = data.data || [];
        this.daoqiongsidataShow2 = true;

      } else {
        this.daoqiongsidayindata = data.data || [];
        this.daoqiongsidataShow1 = true;
        console.log(this.daoqiongsidayindata.length);

      }


    },
    handleCurrentChange(e) {
      this.daoqiongsipage.index = e;
      this._getdaoqiongsi();
    },

    // 获取道琼斯tabel
    _getdaoqiongsi() {
      this.$http.post("api/PaymentIntegration/GetDowjonesPageList", {
        archivesNo: this.formLabelAlign.no,  // 客户编号
        orderBy: "StartTime DESC",
        storeId: "577755280440430592",
        source: "FISH",
        page: this.daoqiongsipage
      }).then((res) => {
        if (res.data.code !== 0) return this.$message.error(res.data.message);

        res.data.data = res.data.data.map((item) => {
          let newdate = moment.utc(item.startTime * 1000).format('HH:mm:ss') + ' GMT';
          let newdate2 = moment(item.startTime * 1000).format('YYYY年MM月DD日 HH:mm:ss');

          item.StartTime = `${newdate2}    (${newdate})`;
          if (item.searchBri == null) {
            item.searchbring = ''
          } else {
            // item.searchbring = moment(item.searchBri).format('DD MMM YYYY');
            item.searchbring = moment(item.searchBri).format('YYYY年MM月DD日');
          }

          if (item.completeTime == null) {
            item.startime = '查询中'
          } else {
            item.startime = moment(item.startTime * 1000).format('YYYY-MM-DD HH:mm:ss');
          }

          return item;
        });
        if (res.data.page.total > 10) {
          this.showPagination = true;
          this.daoqiongsidata = res.data.data;
          this.daoqiongsipage = res.data.page;
        } else {
          this.showPagination = false;
          this.daoqiongsidata = res.data.data;
        }


      });
    },
    //  客户调查表事件
    // --------------------------------------------------------------------------------
    // 获取客户调查表结构
    _kehudiaochabiao() {
      this.$http.get("/api/Survey/QueryCustomerSurvey", {
        params: {
          // 7672162
          customer: this.formLabelAlign.no,
          survey_code: 'JZDC'
        }
      }).then((res) => {
        if (res.data.code !== 1) return this.$message.error(res.data.message);
        this.kehudiaochabiaodata1 = res.data.data;

      });


    },

    async kehudiaochabiaoxiangqing(e) {
      const { data: res } = await this.$http.get("/api/Survey/GetSurveyStructure",
        {
          params: { survey_code: "JZDC" }
        });
      if (res.code == -1) return this.$message.error(res.message);
      this.kehudiaochabiaodata2 = res.data;
      this.GetCustomerSurveyDetail(e);
    },
    async GetCustomerSurveyDetail(e) {
      const { data: res } = await this.$http.get("/api/Survey/GetCustomerSurveyDetail",
        {
          params: { customer_survey_id: e.customer_survey_id }
        });
      if (res.code == -1) return this.$message.error(res.message);
      //给表结构赋值
      this.kehudiaochabiaodata2.items.forEach(element => {
        // console.log("item====>", element);
        res.data.forEach(d => {
          if (element.survey_item_id == d.survey_item_id) {
            // console.log("赋值了====>", element.survey_item_id);
            element.survey_item_value = d.survey_item_value
          }
        }
        )
      });
      console.log("赋值后表结构====>", this.kehudiaochabiaodata2);
      // 重新组织表结构
      const menusLevel1 = [];
      this.kehudiaochabiaodata2.items.forEach((element) => {
        if (
          element.survey_item_parent_id === null ||
          element.survey_item_parent_id === "0" ||
          element.survey_item_parent_id === undefined ||
          element.survey_item_parent_id === ""
        ) {
          menusLevel1.push(element);

          const menusLevel2 = [];
          this.kehudiaochabiaodata2.items.forEach((v) => {
            if (v.survey_item_parent_id === element.survey_item_id) {
              menusLevel2.push(v);
            }
          });
          element.children = menusLevel2;
        }
      });

      this.tableData2 = menusLevel1; // res.list
      console.log("组织后表结构====>", this.tableData2);
      this.kehudiaochabiaoshow = true;
    },



    // 风险评估事件
    // --------------------------------------------------------------------------------
    //  获取表结构
    _getfengxianpinggu() {
      this.$http.get("/api/Survey/QueryCustomerSurvey", {
        params: {
          customer: this.formLabelAlign.no,
          survey_code: 'FXPG'
        }
      }).then((res) => {
        if (res.data.code !== 1) {
          this.$message.error(res.data.message);
          return;
        }
        this.fengxianpinggudata = res.data.data;
      });

    },
    // 获取风险评估详情
    fenxianpingguxiangqing(e) {
      this.zongdefen = e.total_score
      this.$http.get("/api/Survey/GetSurveyStructure", {
        params: {
          survey_code: 'FXPG'
        }
      }).then((res) => {
        if (res.data.code !== 1) {
          this.$message.error(res.data.message);
          return;
        }
        this.fengxianpinggutable = res.data.data;
        this.dengxianpinggutabel2 = this.fengxianpinggutable.items
        this.fengxianpinggushow = true;
      }).then(() => {
        this.$http.get("/api/Survey/GetCustomerSurveyDetail", {
          params: {
            customer_survey_id: e.customer_survey_id
          }
        }).then((res) => {
          if (res.data.code !== 1) {
            this.$message.error(res.data.message);
            return;
          }
          this.fengxianpinggublue = res.data.data;

          this.highlightMatchedCells();

        });
      });
    },
    // 选项框变蓝色
    highlightMatchedCells() {
      const table = this.$el.querySelector('.risk-assessment-table');
      if (!table) return;

      this.resetCellStyles(table);
      const rows = table.querySelectorAll('tr');
      this.fengxianpinggublue.forEach((item) => {
        const targetId = item.survey_item_id;
        rows.forEach((row) => {
          const cell = row.querySelector(`[id="${targetId}"]`);
          if (cell && item.survey_item_value == 'true') {
            cell.classList.add('cell-selected');

          }
        });
      });
    },
    resetCellStyles(table) {
      const cells = table.querySelectorAll('td[id]');
      cells.forEach((cell) => {
        cell.classList.remove('cell-selected');

      });
    },


    // 附件事件
    // --------------------------------------------------------------------------------
    fujianhandleEdit(index, item) {
      this.fujianurl = '';
      console.log(index, item);
      this.$http.get("/FileUpload/GetBase64", {
        params: {
          filekey: item.mD5,
        }
      }).then((res) => {
        if (item.type == 'scan-pdf') {
          this.fujianleixing = 'application/pdf';
        } else {
          this.fujianleixing = 'image/jpeg';
        }

        // 解析 base64 字符串并转换为 Blob 对象
        const binaryData = atob(
          res.data.replace(/^data:image\/\w+;base64,/, "")
        );
        const arrayBuffer = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: this.fujianleixing }); // 根据实际图片格式调整 MIME 类型

        // 生成一个 URL 来显示图片
        this.fujianurl = URL.createObjectURL(blob);

      })
      this.fujianshow = true;

    },




    // -----------------------------------------------------------------------------------

    // 身份证类型字典
    _getGeneralStructureType() {
      this.generalStructureTypes = JSON.parse(window.localStorage.getItem("generalStructureTypes"));
     
    },


    // 搜索获取客户信息
    _customernamedate() {
      this.$http.post("/api/Archives/GetMergeArchives", {
        // 30209
        // 2845432
        // 8976714
        // 3686694
        no: this.formLabelAlign.no,
      }).then((res) => {
        if (res.data.code !== 0) {
          this.$message.error(res.data.message);
          return;
        }
        if (res.data.data.attachments.length == 0) {

          this.fujianshuzu = []
        } else {
          this.fujianshuzu = res.data.data.attachments[0].attachments
        }

        console.log(res.data.data);
        this.kehujibenxixin = res.data.data.archives;
        this.lianxifangshi = res.data.data.contacts;
        console.log("证件信息====>", res.data.data.credentials);
      

        this.credentiales = res.data.data.credentials;

      });
    },

    // 获取联系方式字典
    getLianxifangshizidain() {
      this.$http.get("/api/Archives/GetDictionaryItem", {
        params: {
          code: 'contact-type',
        }
      }).then((res) => {
        if (res.data.code !== 1) {
          this.$message.error(res.data.message);
          return;
        }
        this.lianxifangshizidain = res.data.data;
      });
    },





    // 显示证件详细信息
    async showCredentialInfo(credential) {
      // 去除credential.value的值为空字符串



      this.selectedCredential = credential;

      // 清空数据
      this.fields = [];
      this.imageUrls = [];
      this.otherImages = [];  // 存储 "证件其他图片" 的值

      // 遍历字段
      for (const item of credential.value) {


        const label = this.getPropertyLabel(item.id, credential.generalStructureType);

        if (label == "") {
          console.log("未找到字段", item);
          continue;
        }

        if (label.includes("证件其他图片")) {

          item.value = '';
        }
        else if (
          label.includes("证件头像图片")
        ) {
          item.value = ''
        }
        else if (
          label.includes("证件正面图片") ||
          label.includes("证件背面图片")

        ) {
          // 图片字段，获取图片URL
          const imageUrl = await this.fetchImageUrl(item.value
          );
          if (imageUrl) {
            this.imageUrls.push({ label, url: imageUrl });
          }
        } else {
          if (label.includes("性别")) {
            if (item.value == 12) {
              item.value = "女";
            } else if (item.value == 11) {
              item.value = "男";
            }
          }

          // 普通字段，添加到 fields
          this.fields.push({ label, value: item.value });
        }
      }

      this.zhengjianshow = true;
    },

    convertFieldValue(field) {

      // 根据 label 判断是否需要转换
      if (field.label === "姓名") {
        if (field.value == null || field.value == "") {
          return '';

        } else {
          return `${field.value} (${this.formLabelAlign.no})`;

        }



      } else if (field.label === "中文姓名") {
        if (field.value == null || field.value == "") {
          return '';
        } else {
          return `${field.value} (${this.formLabelAlign.no})`;
        }


      } else {
        return field.value; // 对其他字段返回原值
      }

    },

    // 获取图片 URL
    async fetchImageUrl(imageId) {
      try {
        const response = await this.$http.get("FileUpload/GetBase64", {
          params: { filekey: imageId }
        });
        if (response.data) {
          const base64String = response.data;
          return `data:image/jpeg;base64,${base64String}`;
        } else {
          return null;
        }
      } catch (error) {
        console.error("图片加载失败", error);
        return null;
      }
    },

    // 获取证件类型名称
    getGeneralStructureType(typeId) {
      const type = this.generalStructureTypes.find(
        (item) => item.id === typeId
      );
      return type ? type.code : "未知类型";
    },

    // 获取字段标签
    getPropertyLabel(propertyId, typeId) {
      const type = this.generalStructureTypes.find((item) => item.id === typeId);

      if (type) {
        const prop = type.properties.find((prop) => prop.id === propertyId);
        return prop ? (prop.isHidden ? "" : prop.languagePack[0].v) : "";

      }
      return "";
    },

    // 获取字段属性
    getPropertyById(propertyId, typeId) {
      const type = this.generalStructureTypes.find((item) => item.id === typeId);
      if (type) {
        return type.propertyes.find((prop) => prop.id === propertyId);
      }
      return null;
    },




  }
}
</script>
<style lang="less" scoped>
/deep/.el-checkbox__inner {
  width: 50px;
  /* 调整宽度 */
  height: 50px;
  /* 调整高度 */
}

/deep/.el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 24px;
  left: 8px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 24px;
  transition: transform .15s ease-in .05s;
  transform-origin: center;
}

.detailsPop {
  font-size: 16px;

  /deep/.u-transition {
    z-index: 996 !important;
  }

  .detailsList {
    width: 1200px;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 2px solid #000;

    .item-info {
      border-bottom: 2px solid #000;

      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
      }


      .item-content {
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >view {
          >view {
            padding: 2px 0;
          }
        }
      }
    }

    .header-title {
      max-height: 500px;
      overflow: auto;
      box-sizing: border-box;

      .item-ul {
        margin: 5px 0;
        border-bottom: 1px solid #8e8c8c;

        .itembox {
          //虚线
          border-top: 1px dashed #7a7a7a;
        }

        .item-li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0;

          .paymentCode {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .paymentCode-title {
              width: 130px;
            }

            .paymentQrCode-box {
              display: flex;
              align-items: center;
            }

            .paymentQrCode {
              width: 70px;
            }

            .component-container {
              width: 40%;
            }
          }
        }

        .attachBtn {
          .attachment {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 0;

            >div {
              display: flex;
              align-items: center;
            }
          }
        }

        .button {
          .u-button {
            margin: 0 10px;
          }
        }
      }
    }
  }
}

.new-table-daoqiongsi {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  font-family: 'Roboto', sans-serif;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);

  .new-daoqiongsi-td1 {
    width: 12%;
    text-align: left;
    display: flex;

  }

  .new-daoqiongsi-td2 {
    width: 11%;
    text-align: left;
    font-size: 10px;
    align-items: center;
  }

  .new-daoqiongsi-td3 {
    width: 11%;
    text-align: left;
    font-size: 10px;
    align-items: center;
  }

  .new-daoqiongsi-td4 {
    width: 11%;
    text-align: left;
    font-size: 10px;
    align-items: center;
  }

  .new-daoqiongsi-td5 {
    width: 11%;
    text-align: left;
    font-size: 10px;
    align-items: center;

  }

  .new-daoqiongsi-td6 {
    width: 11%;
    text-align: left;
    font-size: 10px;
    align-items: center;

  }

  .new-daoqiongsi-td7 {
    width: 16%;
    text-align: left;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    align-items: center;

  }

  .new-daoqiongsi-td8 {
    width: 11%;
    text-align: left;
    font-size: 10px;
    align-items: center;

  }

  .new-daoqiongsi-td9 {
    width: 6%;
    font-size: 10px;
    align-items: center;

  }
}

.old-table-daoqiongsi {
  width: 100%;
  padding-top: 15px;
  display: flex;
  flex-wrap: nowrap;

  .old-daoqiongsi-td1 {
    width: 151.29px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }

  .old-daoqiongsi-td2 {
    width: 151.19px;
    text-align: left;
    margin-left: 12px;
  }

  .old-daoqiongsi-td3 {
    width: 121.29px;
    text-align: left;
    margin-left: 10px;
  }

  .old-daoqiongsi-td4 {
    width: 183.19px;
    text-align: left;
    padding-right: 20px;
    margin-left: 18px;
  }

  .old-daoqiongsi-td5 {
    width: 107.26px;
    text-align: left;
    padding-left: 18px;
  }

  .old-daoqiongsi-td6 {
    width: 53px;
    text-align: left;
    padding-left: 10px;
  }
}


@media print {
  .cell-selected {
    background-color: #01060a;
    color: #f2f2f8;
    -webkit-print-color-adjust: exact;
  }

}

.cell-selected {
  background-color: #01090f;
  color: #f6f6fd;
}

//  button {
//      margin: 10px;
//       padding: 10px;
//       background-color: #4CAF50;
//       color: white;
//       border: none;
//       cursor: pointer; 
//   }

//  button:hover {
//    background-color: #45a049;
//  }

.fxpgcontainer {

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #000000;
      padding: 5px;
      text-align: center;
    }

  }


}

.kehudiaochabiao {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #000000;
    padding: 5px;
    text-align: center;
  }
}
</style>