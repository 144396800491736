<template>
  <div class="container">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>USDT追踪</el-breadcrumb-item>
      <el-breadcrumb-item>地址</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="header">
        <div class="balance row">
          
        </div>
        <div>
          
        </div>
      </div>
      <el-card>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="4">  
            <el-select v-model="UdizhiPoper" clearable placeholder="请选择地区">
                 <el-option
                    v-for="item in diqudizhiInfo"
                    :key="item.value"
                    :label="item.name"
                     :value="item.value">
                 </el-option>
             </el-select> 
         </el-col>
          <el-col :span="4">
            <el-select v-model="UleixingPoper" clearable placeholder="请选择客户类型">
                 <el-option
                    v-for="item in CoustormType"
                    :key="item.value"
                    :label="item.name"
                     :value="item.value">
                 </el-option>
             </el-select> 
          </el-col>
          <el-col :span="4"><el-button type="warning" @click="getTrackingSetting" >搜索</el-button> </el-col>
          <el-col :span="4">
           参考价:
          </el-col>
           <el-col :span="8">
            <span>余额总计: <span style="color: red; font-size: 28px; font-weight: bold">{{ result.integerPart }}</span><span style="color: red; font-size: 22px; font-weight: bold">.{{ result.decimalPart }}</span> </span>

            <!-- <span v-for="item in result" :key="item.id">
              {{ item.currency }} - {{ item.chain }}:
              <span style="font-size: 18px; color: red">{{
                item.totalBalance.toLocaleString()
              }}</span> 。</span> -->
       
           </el-col>
         </el-row>
       
      </el-card>
      <el-card>
        <el-row>
          <el-col>
            <el-col :span="4"><el-button type="warning">自己</el-button> </el-col>
            <el-col v-show="deletshow == false"  :span="4"><el-button type="warning" @click="deletshow =true">编辑</el-button> </el-col>
            <el-col :span="4" v-show="deletshow == true" ><el-button type="warning" @click="deletshow =false">完成</el-button> </el-col>
            <el-col :span="4"><el-button type="primary" @click="addAddressTracking">添加地址追踪</el-button> </el-col>

          </el-col>
         </el-row>
      </el-card>


      <div>
        <!-- <el-table
          :data="tableData"
          border
          style="width: 100%"
          :row-key="(row) => row.id"
        >
          <el-table-column label="别名" prop="alisa" />
          <el-table-column label="币种" prop="currency" />
          <el-table-column label="链/协议" prop="chain" />
          <el-table-column label="地址" prop="address" />
          <el-table-column label="余额" prop="balance" />
          <el-table-column label="是否启用">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isEnable">是</el-tag>
              <el-tag type="danger" v-else>否</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button type="text" size="small" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table> -->

 
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 200, 300, 400, 500]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      <template>
        <div class="main-container">
          <div v-for="(item,index) in tableData" :key="index"  class="box">
            <div class="title"> 
            <i v-show="deletshow" class="el-icon-circle-close" @click="deleteall(item)"></i>
            <div class="name"><div class="nameC" @click="goUserProfile(item.archivesNo)"> <span class="name-label">姓名:</span><span class="name-value">{{item.items[0].archivesName}}({{ item.archivesNo }})-{{ getRegionName(item.items[0].area) }}-{{ getRegionName1(item.items[0].type) }}</span></div>
            <div class="count"> <span class="count-label">余额</span><span class="count-value">{{ item.account.integerPart }}</span><span class="count-value1">.{{ item.account.decimalPart }}</span> </div></div>
          </div> 
          <div class="content">
             <div v-for="(jtem,jndex) in item.items" :key="jndex" class="address">   
                  <div @click="handleClick(jtem)" class="address1"><span class="address-label">地址:</span><span class="address-value">{{formatString(jtem.address) }}</span>
                    <!-- <span class="tishi">{{ jtem.isEnable?"启用":"未启用" }}</span><span class="tishi">{{ jtem.isInner?"是内部地址":"不是内部地址" }}</span> -->
                   </div> 
                  <div class="address2">(<span class="balance">{{ jtem.account.integerPart }}.</span> <span class="balance1">{{ jtem.account.decimalPart }}</span>) <i v-show="deletshow" style="font-size: 17px;" @click="deleteTracking([jtem.id])" class="el-icon-error"></i> </div>
               </div>
              </div> 
           </div>  
        </div>
      </template>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[100, 200, 300, 400, 500]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>

      <el-dialog :title="addressTracking" :visible.sync="dialogAddress">
        <el-form :model="form">
          <el-form-item label="别名" label-width="120px">
            <el-input v-model="form.alisa" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="地址" label-width="120px">
            <el-input v-model="form.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="币种" label-width="120px">
            <el-select
              v-model="form.currency"
              placeholder="请选择币种"
              @change="handleCurrencyChange"
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="item in currencyList"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="链/协议" label-width="120px">
            <el-select v-model="form.chain" placeholder="请选择链/协议">
              <el-option
                :label="item.protocol"
                :value="item.protocol"
                v-for="item in chainList"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用" label-width="120px">
            <el-switch
              v-model="form.isEnable"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="是否是内部地址" label-width="120px">
            <el-switch
              v-model="form.isInner"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAddress = false">取 消</el-button>
          <el-button type="primary" @click="handleAddAddressTracking"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  watch: {},
  props: {},
  data() {
    return {
      deletshow: false,
      UleixingPoper:"",
      UdizhiPoper:"",
      diqudizhiInfo: [
        {
          name: '香港',
          value: 'HongKong',
        },
        {
          name: '澳门',
          value: 'Macao',
        },
        {
          name: '新加坡',
          value: 'Singapore',
        },
      ],//地区地址信息
      CoustormType: [
        {
          name: '客人',
          value: 'guest',
        },
        {
          name: '供应商',
          value: 'supplier',
        },
        {
          name: '商家',
          value: 'business',
        },
                        ],//客人类型
      addressTracking: "添加地址追踪",
      dialogAddress: false,
      currentPage: 1,
      form: {
        alisa: "",
        currency: "",
        chain: "",
        address: "",
        isEnable: true,
        isInner: true,
      },
      currencyList: [],
      chainList: [],

      tableData: [],
      page: {
        index: 1,
        size: 100,
        total: 0,
      },
      result: [],
    };
  },
  onLoad() {},
  mounted() {
    this.getTrackingSetting();
    this.getCurrencyList();
  },
  onShow() {},
  methods: {
    // ---------------------------------------------------
    // 公共事件



 getRegionName(value) {
    const diqudizhiInfo = [
        { name: '香港', value: 'HongKong' },
        { name: '澳门', value: 'Macao' },
        { name: '新加坡', value: 'Singapore' }
    ];

    if (value === "") {
        return "";
    }

    const result = diqudizhiInfo.find(item => item.value === value);
    return result? result.name : "";
},
getRegionName1(value) {
    const diqudizhiInfo = [
    {
          name: '客人',
          value: 'guest',
        },
        {
          name: '供应商',
          value: 'supplier',
        },
        {
          name: '商家',
          value: 'business',
        },
    ];

    if (value === "") {
        return "";
    }

    const result = diqudizhiInfo.find(item => item.value === value);
    return result? result.name : "";
},

    formatNumberWithDifferentStyles(num) {
      // 将数字拆分为整数部分和小数部分，并保留小数点后两位
      const [integerPart, decimalPart] = num.toFixed(2).split('.');
      return {
        integerPart: integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ","), // 添加逗号
        decimalPart: decimalPart // 直接使用小数点后的部分
      };
},
    formatString(inputStr, maxLength = 10, ellipsis = '...') {
    if (inputStr.length <= maxLength) {
        return inputStr;
    } else {
        const halfLength = Math.floor((maxLength - ellipsis.length) / 2);
        const start = inputStr.slice(0, halfLength);
        const end = inputStr.slice(-halfLength);
        return start + ellipsis + end;
    }
},
    // 跳转用户页面
    goUserProfile(data){
      if(data==''){
        return;
      }
      let queryString = `?no=${data}`;
      // this.$router.push({ path: '/home/UserProfile', query: { no:e } });
      window.open(`/home/UserProfile${queryString}`, "_blank", 'width=1920,height=1080');
     
     
    },
     formatNumberWithCommas(number) {
  // 将数字转换为字符串
  const numberStr = number.toString();

  // 查找小数点的位置
  const decimalPointIndex = numberStr.indexOf('.');

  // 提取整数部分和小数部分
  let integerPart = decimalPointIndex !== -1 ? numberStr.slice(0, decimalPointIndex) : numberStr;
  let decimalPart = decimalPointIndex !== -1 ? numberStr.slice(decimalPointIndex + 1) : '';

  // 如果小数部分长度超过2位，则截取前两位
  if (decimalPart.length > 2) {
    decimalPart = decimalPart.slice(0, 2);
  }

  // 对整数部分每三位添加一个逗号
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // 拼接整数部分和小数部分（如果需要的话）
  return formattedIntegerPart + (decimalPart ? `.${decimalPart}` : '');
},
    // 删除接口 ---------------------------------------------------------------------------------
  async deleteall(item) {
    const ids = [];

item.items.forEach(obj => {
  if (obj.id !== undefined) {
    ids.push(obj.id);
  }
});
    this.$confirm("此操作将永久删除该地址追踪, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
      showCancelButton: true,
          }).then(() => {
            console.log(ids,"ss");
            
          this.deleteTracking(ids);
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

     
    },
  async deleteTracking(ids) {
    try {
     
        let randomNum =Math.random() ;
        console.log(randomNum,"ss1");
        
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
           "trace-id": randomNum,
         
        };
        // 发送 delte 请求
        const { data: response } = await axios.delete(
           "https://nxapi.seek940.com/wallet/api/v1/Tracking/BatchDelete",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/BatchDelete",
          {  headers
          ,
          data:ids} 
        );

        // 检查响应数据是否存在
        if (response && response.data) {
         
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getTrackingSetting();
        
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
  },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page.size = val;
      this.getTrackingSetting();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.index = val;
      this.getTrackingSetting();
    },
    handleClick(row) {
      // TODO: 跳转到某个页面并��带参数
      localStorage.setItem("listData", JSON.stringify(row));
      this.$router.push({
        path: "/home/transactionHistory",
      });
    },
    addAddressTracking() {
      this.dialogAddress = true;
      this.addressTracking = "添加地址追踪";
      this.form = {
        id: "",
        alisa: "",
        currency: "",
        chain: "",
        address: "",
        isEnable: true,
      };
    },
    handleEdit(row) {
      this.dialogAddress = true;
      this.addressTracking = "编辑地址追踪";
      this.form = {
        id: row.id,
        alisa: row.alisa,
        currency: row.currency,
        chain: row.chain,
        address: row.address,
        isEnable: row.isEnable,
      };
    },
    // 添加地址追踪
    async handleAddAddressTracking() {
      console.log(this.form);
      try {
        // 生成一个16位的随机整数
        const min = Math.pow(2, 15); // 2^15
        const max = Math.pow(2, 16) - 1; // 2^16 - 1
        let randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          "trace-id": randomNum,
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/AddOrUpdateTracking",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/AddOrUpdateTracking",
          this.form,
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.code == 0) {
          // 处理响应数据
          this.dialogAddress = false;
          this.getTrackingSetting();
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    // 币种
    async handleCurrencyChange(val) {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.get(
          "https://nxapi.seek940.com/foundation/api/Foundation/currenciyProtocol",
          // "http://ttmarket.ttmarket.com.cn:28080/foundation/api/Foundation/currenciyProtocol",
          {},
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.data) {
          // 处理响应数据
          let data = response.data;
          if (data && data.length > 0) {
            this.chainList = data.filter((item) => {
              return item.code === val;
            }); // 假设数据中有 usdtBalance 属性
          }
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
    },
    //                                            axios 获取跟踪设置  get 请求数据
    async getTrackingSetting() {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.post(
          "https://nxapi.seek940.com/wallet/api/v1/Tracking/GetTrackingGroupPageList",
          // "http://ttmarket.ttmarket.com.cn:28080/wallet/api/v1/Tracking/GetTrackingGroupPageList",
          {
            area:  this.UdizhiPoper,
            type: this.UleixingPoper,
            page: {
              index: this.page.index,
              size: this.page.size,
            },

          },
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.data) {
          // 处理响应数据
          response.data.forEach(element => {
            element.account = 0;
         if (element.items) {
          // 内层循环遍历每个元素中的items数组
          element.items.forEach(item => {
            let balanceValue = item.balance;
                if (typeof balanceValue === 'string') {
                    balanceValue = parseFloat(balanceValue); 
                    if (isNaN(balanceValue)) {
                        // 如果转换失败（不是有效的数字字符串），可以进行相应处理，比如跳过此次累加等
                        return this.$message.error('余额格式不正确');
                    }
                }
                element.account += balanceValue;
                
          });
        }
          // element.account  用以一个方法 保留小数点后面的数字，小数点的前面的每三位数字加一个逗号
         
          element.account = this.formatNumberWithDifferentStyles(element.account);
          // element.balance = this.formatNumberWithDifferentStyles(element.balance);
      });
      // 处理第二遍  
      response.data.forEach(element => {
        // 内层循环遍历每个元素中的items数组
         if (element.items) {
          // 内层循环遍历每个元素中的items数组
          element.items.forEach(item => {
            
                item.account = this.formatNumberWithDifferentStyles(item.balance);
          });
        }
          // element.account  用以一个方法 保留小数点后面的数字，小数点的前面的每三位数字加一个逗号
         
        
          // element.balance = this.formatNumberWithDifferentStyles(element.balance);
      });



          this.tableData = response.data; // 假设数据中有 usdtBalance 属性
          console.log(this.tableData,"1");
          
          this.page = response.page; // 假设数据中有 page 属性
          // const summedData = this.sumBalancesByCurrencyAndChain(this.tableData);
          this.result = this.formatNumberWithDifferentStyles(response.totalBalance);
          console.log(this.result);
        } else {
          console.error("Invalid response:", response);
          this.$message.error("未分组");
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
      //   this.tableData = response.data.data[0].balance;
    },
    sumBalancesByCurrencyAndChain(data) {
  // 循环data中的每一个对象中 account 的值 的总和
  let accountSum = 0;

  data.forEach(item => {
    // 尝试移除account值中的逗号，并转换为数字
    const cleanedAccount = item.account2
    const accountValue = parseFloat(cleanedAccount); // 使用parseFloat来处理可能的小数点

    if (!isNaN(accountValue)) {
      accountSum += accountValue;
    } else {
      console.warn(`Invalid account value: ${item.account}. Skipping.`);
    }
  });

  // 保留小数点后三位并返回字符串
  return accountSum.toFixed(3);
},
    // axios 获取跟踪设置
    async getCurrencyList() {
      try {
        // 添加请求头部
        const headers = {
          "Content-Type": "application/json",
          "auth-id": "D1g4j7cXy578MvZbLQqWzdUeNG",
          // 可以根据需要添加其他头部信息
        };

        // 发送 POST 请求
        const { data: response } = await axios.get(
          "https://nxapi.seek940.com/foundation/api/Foundation/currencies?type=crypto",
          // "http://ttmarket.ttmarket.com.cn:28080/foundation/api/Foundation/currencies?type=crypto",
          {},
          { headers }
        );

        // 检查响应数据是否存在
        if (response && response.data) {
          // 处理响应数据
          this.currencyList = response.data; // 假设数据中有 usdtBalance 属性
        } else {
          console.error("Invalid response:", response);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }
      //   this.tableData = response.data.data[0].balance;
    },
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    .search-box {
      display: flex;
      align-items: center;
    }
    .header {
      padding: 10px 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .balance {
        display: flex;
        align-items: center;
      }
    }
  }
  /deep/.el-form-item__content {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .el-select {
    display: block;
  }
}
  .main-container{
    width: 100%;
  
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

     .box{
      padding: 10px;
      margin: 5px;
      width: 24%;
      height: 200px;
      background-color: #f7f7f7;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
      position: relative;
      border: 1px solid black;
      z-index: 97;
      .title{
        
        width: 100%;
        height: 20%;
      border-bottom: 1px solid black;
      z-index: 97;
      .el-icon-circle-close{
        position: absolute;
        top: -2px;
        right: -2px;
        font-size: 30px;
        cursor: pointer;
        color: red;
        z-index: 99 !important;
      }
      .name{
        width: 95%;
        display: flex;
        justify-content: space-between;

        .nameC{

        .name-label{
        font-size: 15px;
        font-weight: bold;
         }
        .name-value{
        font-size: 14px;
        color: red;
        }
      }

      .nameC:hover{
        background-color: black;
        color: white;
        cursor: pointer;
      }


        .count-label {
        font-size: 15px;
        font-weight: bold
        }
      .count-value{
        font-size: 18px;
        color: red;
        }
        .count-value1{
          font-size: 14px;
          color: red;
        }
      }
      }
      .content{
        width: 100%;
        height: 80%;
        overflow-y: scroll;
       
      .address{

        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
       .address1{
       
           .address-label{
                 font-size: 15px;
                 font-weight: bold;
                   }
            .address-value{
                  font-size: 13px;
       
                   } 

            .tishi {
                font-size: 14px;
                color: blue;
                margin-left: 5px;
            }
             }
       .address2{
       
             .balance{
                  font-size: 15px;
                  color: green ;
                   font-weight: bold
               }
               .balance1{
                  font-size: 11px;
                  color: green;
                }
           }
      
       }
     .address:hover{
        background-color: black;
        color: white;
      }
    
     }
      }
     
     .content::-webkit-scrollbar {
    display: none;
}
  }
</style>
