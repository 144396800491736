<template>
  <div class="container">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item>店铺账户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="search-box">
        <div class="search-item">
          <div>选择商家：</div>
          <el-popover
            placement="bottom"
            width="200"
            v-model="tenantPopover"
            trigger="click"
          >
            <div class="popover-content">
              <div
                v-for="(item, index) in tenantList"
                :key="index"
                @click="handleTenantChange(item)"
              >
                {{ item.tenant_name }}
              </div>
            </div>
            <el-button slot="reference">{{ tenantInfo.tenant_name }}</el-button>
          </el-popover>
        </div>
        <div class="search-item1">
          <div class="selectStore">选择店铺：</div>
          <div class="popover-content1">
            <div
              v-for="(item, index) in storeList"
              :key="index"
              :class="{ 'red-background': index === activeIndex }"
              @click="handleStoreChange(item, index)"
            >
              {{ item.store_name }}
            </div>
          </div>
        </div>
      </div>

      <div class="account-balance-box">
        <el-tabs v-model="activeTabs" class="tabs-container">
          <el-tab-pane label="1001" name="Legal">
            <div class="currency-box">
              <div
                class="currency-li row"
                @click="changeCurrency(item)"
                v-for="(item, index) in cashBalanceList"
                :key="'cashBalanceList' + index"
              >
                <img
                  style="width: 60px; height: 60px"
                  :src="getImgUrl(item.currency_code)"
                  :alt="item.currency_code"
                />
                <div>
                  <div class="currency-name row">
                    <div>{{ item.currency_code }}</div>
                    <div>{{ item.currency_name }}</div>
                  </div>
                  <div class="currency-name row">
                    <div>现金余额：</div>
                    <div
                      :style="{
                        fontSize: '28px',
                        fontWeight: '700',
                        color: getBalanceColor(item.balance),
                      }"
                    >
                      {{ Number(item.balance).toLocaleString() }}
                    </div>
                  </div>
                  <div class="currency-name row">
                    <div>过数余额：</div>
                    <div
                    :style="{
                        fontSize: '28px',
                        fontWeight: '700',
                        color: getBalanceColor(item.availableBalance),
                      }"
                    >
                      {{ Number(item.availableBalance).toLocaleString() }}
                    </div>
                  </div>
                  <div class="currency-name row">
                    <div>可用余额：</div>
                    <div
                    :style="{
                        fontSize: '28px',
                        fontWeight: '700',
                        color: getBalanceColor(item.totalBalance),
                      }"
                    >
                      {{ Number(item.totalBalance).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="1009" name="Virtual">
            <div class="currency-box">
              <div
                class="currency-li row"
                @click="changeCurrency(item)"
                v-for="(item, index) in bridgeBalanceList"
                :key="'bridgeBalanceList' + index"
              >
                <img
                  style="width: 60px; height: 60px"
                  :src="getImgUrl(item.currency_code)"
                  :alt="item.currency_code"
                />
                <div>
                  <div class="currency-name row">
                    <div>{{ item.currency_code }}</div>
                    <div>{{ item.currency_name }}</div>
                  </div>
                  <div class="currency-name row">
                    <div>余额：</div>
                    <div
                    :style="{
                        fontSize: '28px',
                        fontWeight: '700',
                        color: getBalanceColor(item.balance),
                      }"
                    >
                      {{ Number(item.balance).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="1008" name="three">
            <div class="currency-box">
              <div
                class="currency-li row"
                @click="changeCurrency(item)"
                v-for="(item, index) in centerBalance"
                :key="'centerBalance' + index"
              >
                <img
                  style="width: 60px; height: 60px"
                  :src="getImgUrl(item.currency_code)"
                  :alt="item.currency_code"
                />
                <div>
                  <div class="currency-name row">
                    <div>{{ item.currency_code }}</div>
                    <div>{{ item.currency_name }}</div>
                  </div>
                  <div class="currency-name row">
                    <div>余额：</div>
                    <div
                    :style="{
                        fontSize: '28px',
                        fontWeight: '700',
                        color: getBalanceColor(item.balance),
                      }"
                    >
                      {{ Number(item.balance).toLocaleString() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  watch: {},
  data() {
    return {
      activeIndex: 0,
      activeTabs: "Legal",
      tenantPopover: false,
      tenantList: [], // 商家列表
      tenantInfo: {}, // 商家信息
      storeList: [], // 店铺列表
      storeData: [], // 店铺列表
      storeInfo: {}, // 店铺信息
      cashBalanceList: [], // 账户列表
      bridgeBalanceList: [], // 账户列表
      centerBalance: [], //1008账户余额列表
    };
  },
  onLoad() {},
  mounted() {
    this.tenantList = JSON.parse(localStorage.getItem("loginTenantsInfo"));
    this.tenantInfo = this.tenantList[0];
    this.getStoreList();
    this._getshenfenzheng();
  },
  onShow() {},
  methods: {
    /**
     * 根据账户余额返回颜色值
     *
     * @param balance 账户余额
     * @returns 如果余额大于等于0，返回'green'；否则返回'red'
     */
    getBalanceColor(balance) {
      const numBalance = Number(balance);
      return numBalance >= 0 ? "green" : "red";
    },
    // 获取身份证字典
    async _getshenfenzheng() {
      const { data: res } = await this.$http.get(
        "/api/Archives/GetGeneralStructuries",
        {
          params: {},
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      // 把信息存入本地
      localStorage.setItem("generalStructureTypes", JSON.stringify(res.data));
    },

    getImgUrl(currencyCode) {
      try {
        return require(`@/assets/currency/${currencyCode}.png`);
      } catch (error) {
        console.error(
          `Image not found for currency code: ${currencyCode}`,
          error
        );
        return ""; // 默认图片
      }
    },
    // 商家选择
    handleTenantChange(item) {
      this.tenantInfo = item;
      this.getStoreList();
      this.tenantPopover = false;
    },
    handleStoreChange(item, index) {
      this.storeData = [];
      this.activeIndex = index;
      this.storeInfo = item;
      if (item.store_name == "全部" && item.store_id == "") {
        this.storeList.forEach((p) => {
          if (p.store_name != "全部" && p.store_id != "") {
            this.storeData.push(p.store_id);
          }
        });
      } else {
        this.storeData.push(item.store_id);
      }
      console.log(this.storeData);
      this.getAccountBalance();
    },
    // 获取商家店铺列表
    async getStoreList() {
      const { data: res } = await this.$http.get(
        "v1/Store/GetStoreInfoByTenant",
        {
          params: { tenant: this.tenantInfo.tenant_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      res.data.unshift({
        store_id: "",
        store_name: "全部",
      });
      res.data = res.data.filter(
        (item) => !item.store_id.includes("608322287283867648")
      );
      res.data = res.data.filter(
        (item) => !item.store_id.includes("629155004615364608")
      );
      res.data = res.data.filter(
        (item) => !item.store_id.includes("644089413139304448")
      );
      res.data = res.data.filter(
        (item) => !item.store_id.includes("644089484933206016")
      );

      this.storeList = res.data.filter(
        (item) => !item.store_name.includes("停用")
      );
      this.storeInfo = res.data[0];
      this.storeList.forEach((item) => {
        if (item.store_name != "全部" && item.store_id != "") {
          this.storeData.push(item.store_id);
        }
      });
      this.getAccountBalance();
    },
    // 获取店铺账户列表
    // async getStoreList() {
    //   const { data: res } = await this.$http.get(
    //     "v1/Store/GetStoreInfoByTenant",
    //     {
    //       params: {},
    //     }
    //   );
    //   if (res.code == -1) return this.$message.error(res.message);
    // },
    // 获取账户余额
    async getAccountBalance() {
      const { data: res } = await this.$http.post(
        "StoreAccount/GetStoreAccountBalanceSumNew",
        this.storeData
      );
      if (res.code == -1) return this.$message.error(res.message);
      res.cash_balance.forEach((item) => {
        item.availableBalance = 0;
        item.totalBalance = item.balance;
      });
      this.cashBalanceList = res.cash_balance;
      this.bridgeBalanceList = res.bridge_balance;
      this.centerBalance = res.center_balance;

      this.getTempBalance(res.cash_balance);
    },
    // 获取账户余额
    async getTempBalance(cashBalance) {
      if (
        this.storeInfo.store_id == "" &&
        this.storeInfo.store_name == "全部"
      ) {
        // 把 this.storeList 转换为数组，并过滤掉 store_name 为 "全部" 和 store_id 为 "" 的项，然后转换为数组
        let storeList = this.storeList.filter(
          (item) => item.store_name !== "全部" && item.store_id !== ""
        );
        storeList = storeList.map((item) => item.store_id);
        console.log(storeList, "storeList");

        let tempBalance = [];
        let tempBalanceList = [];

        try {
          const { data: res } = await this.$http.post(
            "StoreAccount/GetTempBalanceNew",
            storeList
          );
          if (res.code == -1) return this.$message.error(res.message);
          console.log(res.data, "res.data"); // 添加日志

          // 验证 res.data 是否为数组

          tempBalance = res.data || [];
        } catch (error) {
          console.error("Error fetching temp balance:", error);
        }

        console.log(tempBalance, "tempBalance");

        // 合并相同币种的余额
        tempBalanceList = tempBalance.reduce((acc, balance) => {
          const existingBalance = acc.find(
            (item) => item.currencyCode === balance.currencyCode
          );
          if (existingBalance) {
            existingBalance.availableBalance += balance.availableBalance;
          } else {
            acc.push({ ...balance });
          }
          return acc;
        }, []);

        tempBalanceList.forEach((cItem) => {
          cashBalance.forEach((item) => {
            if (item.balance == "" && cItem.availableBalance == "") {
              if (item.currency_code == cItem.currencyCode) {
                item.availableBalance = Number(cItem.availableBalance);
                item.totalBalance =
                  Number(item.balance) - Number(cItem.availableBalance);
              }
            }
          });
        });
        this.$set(this, "cashBalanceList", cashBalance);
      } else {
        try {
          const { data: res } = await this.$http.get(
            "StoreAccount/GetTempBalance",
            {
              params: {
                store: this.storeInfo.store_id,
              },
            }
          );
          if (res.code == -1) return this.$message.error(res.message);

          // 验证 res.data 是否为数组
          if (res.data && Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach((cItem) => {
              cashBalance.forEach((item) => {
                // 将空字符串视为0进行处理
                let itemBalance = parseFloat(item.balance) || 0;
                let cItemAvailableBalance =
                  parseFloat(cItem.availableBalance) || 0;

                // 判断 item.currency_code 和 cItem.currencyCode 是否相等
                if (item.currency_code === cItem.currencyCode) {
                  item.availableBalance = cItemAvailableBalance;
                  item.totalBalance = itemBalance - cItemAvailableBalance;
                } else {
                  // alert("item.currency_code 和 cItem.currencyCode 不相等，无法进行匹配计算！");
                }
              });
            });
          } else {
            console.error("res.data is not an array or is empty:", res.data);
          }
          this.$set(this, "cashBalanceList", cashBalance);
        } catch (error) {
          console.error("Error fetching temp balance:", error);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.red-background {
  background-color: red;
}
.container {
  width: 100%;
  height: 100%;
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .search-box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .search-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        > :nth-child(1) {
          width: 80px;
        }
      }
      .search-item1 {
        width: calc(100% - 200px);
        display: flex;
        align-items: center;
        .selectStore {
          width: 80px;
        }
      }
    }
    .account-balance-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      /deep/.el-tabs__nav-scroll {
        padding: 10px 0;
      }
      .tabs-container {
        width: 100%;
        height: 100%;
        /deep/.el-tabs__item {
          font-size: 16px;
        }
        .currency-box {
          padding: 20px 5px;
          box-sizing: border-box;
          overflow: auto;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          .currency-li {
            width: 19.4%;
            margin: 10px 5px 0;
            cursor: pointer;
            display: flex;
            border: 1px solid #000;
            padding: 0 0 0 20px;
            align-items: center;
            box-sizing: border-box;
            > div {
              color: #333333;
              margin-left: 10px;
            }
            .currency-name {
              padding: 5px 0;
              align-items: center;
              > div {
                height: 30px;
                line-height: 30px;
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;

  > div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
.popover-content1 {
  width: 90%;
  overflow-x: auto;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  > div {
    min-width: 100px;
    max-width: 500px;
    white-space: nowrap;
    padding: 0 20px;
    height: 50px;
    box-sizing: border-box;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
</style>
