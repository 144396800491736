<template>
    <div>
      <!-- 面包屑导航区 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>交易中心</el-breadcrumb-item>
        <el-breadcrumb-item>资产统计</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 卡片视图 -->
      <div style="text-align: left;">
        <TradeCenterAccountTotal></TradeCenterAccountTotal>
      </div>
    </div>
  </template>
  
  <script>

  import TradeCenterAccountTotal from "./components/Trade-Center-Account-Total.vue";
  export default {
    components: {TradeCenterAccountTotal },
    data() {
      return {
        currentPlatformCode: "",    
        // currentComponent:"Cash940AccountTotal"
      };
    },
   
    methods: {
    },
  };
  </script>
  
  <style lang="less" scoped>
  </style>
  