<template>
  <div class="listOfIoTDevices">
    <el-card>
         <div style="display: flex;">
          <div>
             <!-- 下拉框筛选 -->
    <el-select v-model="selectedType" placeholder="请选择设备类型" @change="filterData" style="margin-bottom: 20px;">
      <el-option
        v-for="item in deviceOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
          </div>
          <div>
      <el-button type="primary" @click="_getshebeidata">刷新</el-button> 
        </div>

         </div>
    </el-card>
 
    <el-table
      :data="filteredData"
      boder
      header-align="center"
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column prop="name" label="设备名称"></el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          {{ getTypeDisplay(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="onlineState" label="是否在线">
        <template slot-scope="scope">
          {{ getTypeDisplay(scope.row.onlineState) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      shebeidata: [], // 原-设备数据列表
      // 筛选后的数据
      filteredData: [],
      // 设备类型选项
      deviceOptions: [
        { value: "", label: "全部" },
        { value: "GRGBanking", label: "TRC 设备" },
        { value: "GRGPassport", label: "护照机设备" },
        { value: "Dowjones", label: "道琼斯设备" }
      ],
      // 当前选择的设备类型
      selectedType:"",
    };
  },
  mounted() {
    this._getshebeidata();
  },
  methods: {
    getTypeDisplay(type) {
      switch (type) {
        case "GRGBanking":
          return "TRC 设备";
        case "GRGPassport":
          return "护照机设备";
        case "Dowjones":
          return "道琼斯设备";
        case "online":
          return "在线";
        case "offline":
          return "离线";
        default:
          return "未知设备";
      }
    },
    _getshebeidata() {
      axios
        .get("https://nxapi.seek940.com/openapi/api/open/getdevicelist")
        .then((res) => {
          if (res.status == 200 && res.data.code == 0) {
            this.shebeidata = res.data.data;
            this.filteredData = this.shebeidata; // 初始化筛选后的数据为全部设备列表

          } else {
            this.$message.error("获取设备列表失败");
          }
        });
    },
      // 筛选数据
      filterData() {
      if (this.selectedType) {   
        this.filteredData = this.shebeidata.filter(item => item.type == this.selectedType);
      } else {
        this.filteredData = this.shebeidata;
      }
    },
    // 看设备是否离线，离线给改变行颜色
    tableRowClassName({ row }) {
  
      return row.onlineState == 'offline' ? "row-offline" : "";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row-offline {
  background: #eb0b0b !important; /* 离线设备行背景色 */
}
</style>
