<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商户管理</el-breadcrumb-item>
      <el-breadcrumb-item>交易单</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="9">
            <el-form-item label="时间范围:">
              <el-date-picker v-model="value2" type="datetimerange" :picker-options="pickerOptions" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" align="right"
                @change="handleDateChange">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="客户编号:">
              <el-input placeholder="请输入客户编号" v-model="customerNo" 
                @keyup.enter.native="handleCustomerChange">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="选择商家:">
              <el-select v-model="tenantPopover" @change="_getstoreData" placeholder="请选择">
                <el-option v-for="(item, index) in tenantList" :key="index" :label="item.tenant_name"
                  :value="item.tenant_id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="选择店铺:">
              <el-select @change="handleStoreChange" v-model="storePopover" multiple collapse-tags placeholder="请选择">
                <el-option v-for="item in storeList" :key="item.value" :label="item.store_name" :value="item.store_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item label="状态:">
              <el-select clearable v-model="statusPopover" placeholder="请选择">
                <el-option v-for="(item, index) in statusList" :key="index" :label="item.status_name"
                  :value="item.status"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="卖出币种:">
              <el-row>
                <el-col :span="12">
                  <el-select clearable v-model="maichubizhongPopover" placeholder="请选择">
                    <el-option v-for="(item, index) in mairubizhongList" :key="index" :label="item.currency_name"
                      :value="item.currency_id"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-input placeholder="请输入卖出金额" type="number" v-model="maichupricePopover" class="input-with-select">
                  </el-input>
                </el-col>
              </el-row>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="买入币种:">
              <el-row>
                <el-col :span="12">
                  <el-select clearable v-model="mairubizhongPopover" placeholder="请选择">
                    <el-option v-for="(item, index) in mairubizhongList" :key="index" :label="item.currency_name"
                      :value="item.currency_id"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <el-input placeholder="请输入买入金额" type="number" v-model="mairupricePopover" class="input-with-select">
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="交易类型:">
              <el-select clearable v-model="jiaoyileixingPopover" placeholder="请选择">
                <el-option v-for="(item, index) in jiaoyileixingList" :key="index" :label="item.name"
                  :value="item.type"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <!-- <el-button icon="el-icon-search" circle @click="getData"> -->
            <el-button type="primary" @click="getData2" plain>搜索</el-button>
            <el-button type="success" @click="exportExcel" plain>导出</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
         
            <el-input placeholder="请输入单号" v-model="DingDandanhao" class="input-with-select">
              <el-button slot="append" @click="_getdanhao" icon="el-icon-search"></el-button>
            </el-input>
        
      
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="queryInfo.pageIndex" :page-sizes="[200, 400,]" :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button type="warning" @click="handleSendSelectedData">批量隐藏</el-button>
          </el-col>
        </el-row>
      </el-form>

      <el-table :data="tableData" border style="width: 100%" :row-key="(row) => row.id" class="custom-table"
        ref="multipleTable">
    
        <el-table-column prop="store_name" label="店铺" align="center"></el-table-column>
        <el-table-column prop="store_exchange_no" label="单号" align="center">
        </el-table-column>
        <el-table-column label="客户" align="center">
          <template slot-scope="scope">
            <span>
              {{ scope.row.customer_name }}{{ scope.row.customer_id }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="买入" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.buy_amount)
              }}{{ scope.row.buy_currency_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="卖出" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.sale_amount)
              }}{{ scope.row.sale_currency_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="汇率" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.rate) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="汇率倒数" align="center">
          <template slot-scope="scope">
            <span>{{ formatAmount(scope.row.rate_reciprocal) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="exchange_type_name" label="交易类型" align="center" width="150">
        </el-table-column>
        <el-table-column prop="create_time" label="时间" width="110" align="center">
        </el-table-column>
        <el-table-column label="是否隐藏" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.hide_flag" active-color="#13ce66" inactive-color="#909399"
              @change="handleHideFlagChange2(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="80"></el-table-column>
        <el-table-column prop="status_name" label="状态" width="100" align="center">
        </el-table-column>
        <el-table-column width="120" align="center">
          <template slot-scope="scope">
            <div style="display: flex; flex-direction: column;  justify-content: center; align-items: center;">
              <el-button type="warning" size="mini" @click="showEditDialog(scope.row)" icon="el-icon-edit">详情
              </el-button>
              <el-button style="margin-right: 8px; margin-top: 5px;" type="info" size="mini"
                @click="showfujianDialog(scope.row)" icon="el-icon-edit">附件
              </el-button>
            </div>

          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pageIndex" :page-sizes="[200, 400,]" :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>

    </el-card>
    <el-dialog title="详细信息" width="1250px" :visible.sync="detailsWindow" center>
      <!-- <el-button type="primary" class="no-print" @click="printfcustomerName">打印</el-button> -->
      <div class="detailsPop">
        <div>
          <div class="detailsList">
            <div class="item-info">
              <div class="item-title">
                <div>{{ detailsInfo.store_exchange_no }}</div>
                <div v-if="detailsInfo.exchange_type == 'Surplus2Cash' || detailsInfo.exchange_type == 'Surplus2Trans'">
                  <div v-if="detailsInfo.select_store_receipt_no !== null || detailsInfo.select_store_receipt_no ">收款单:{{ detailsInfo.select_store_receipt_no}}</div>
                  <el-button v-else  @click="golookaccount(detailsInfo)" >查看流水</el-button>
                </div>
                
                <div @click="_customernamedate(detailsInfo.customer_no)">
                  客户：{{ detailsInfo.customer_name }}({{
                    detailsInfo.customer_no
                  }})
                </div>
              </div>
              <div class="item-content">
                <div>
                  <div>
                    买入：{{ Number(detailsInfo.buy_amount)
                    }}{{ detailsInfo.buy_currency_name }}
                  </div>
                  <div>
                    卖出：{{ Number(detailsInfo.sale_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>买汇价：{{ detailsInfo.rate * 1 }}</div>
                </div>
                <div v-if="
                  detailsInfo.exchange_type != 'Cash2Cash' &&
                  detailsInfo.exchange_type != 'Surplus2Cash'
                ">
                  <div>
                    手续费：{{ Number(detailsInfo.fee_amount)
                    }}{{ detailsInfo.fee_currency_name }}
                  </div>
                  <div>
                    自带：{{ Number(detailsInfo.bring_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    自取：{{ Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
                <div v-if="
                  detailsInfo.exchange_type != 'Cash2Cash' &&
                  detailsInfo.exchange_type != 'Surplus2Cash'
                ">
                  <div>
                    应付：{{
                      Number(detailsInfo.sale_amount) +
                      Number(detailsInfo.bring_amount) -
                      Number(detailsInfo.take_amount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                  <div>
                    待付：{{ Number(detailsInfo.toBePaidAmount)
                    }}{{ detailsInfo.sale_currency_name }}
                  </div>
                </div>
              </div>
              <div v-if="
                detailsInfo.exchange_type == 'Cash2Cash' ||
                detailsInfo.exchange_type == 'Surplus2Cash'
              ">
                <div></div>
                <div>状态：{{ detailsInfo.statusString }}</div>
              </div>
            </div>
            <template>
              <div class="header-title" v-if="
                detailsInfo.exchange_type != 'Cash2Cash' &&
                detailsInfo.exchange_type != 'Surplus2Cash'
              ">
                <div v-for="(item, index) in detailsInfo.details" :key="'details' + index" class="item-ul">
                  <div class="line"></div>
                  <div class="item-li">
                    <div v-if="
                      detailsInfo.details && detailsInfo.details.length > 1
                    ">
                      第{{ index + 1 }}笔
                    </div>
                  </div>
                  <div class="item-li">
                    <div class="item-li">
                      <div>
                        收款人：{{ detailsInfo.customer_name }}({{
                          detailsInfo.customer_no
                        }})
                      </div>
                      
                      <div v-for="(cItem, cIndex) in item.cards" :key="'cItem' + cIndex">
                        <div v-if="cItem.bank_card_type_code == 'BankCard'">
                          {{ cItem.holder_name }}
                          {{ cItem.bank_class_name }} ({{
                            cItem.bank_card_number
                          }})
                        </div>
                        <div v-if="
                          cItem.bank_card_type_code == 'WX' ||
                          cItem.bank_card_type_code == 'Alipay'
                        " class="paymentCode">
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">好友码：</div>
                          </div>
                          <div class="paymentQrCode-box">
                            <div class="paymentQrCode">付款码：</div>
                          </div>
                        </div>
                        <div v-if="cItem.bank_card_type_code == 'Virtual'" class="paymentCode">
                          <div class="paymentCode-title">
                            {{ cItem.holder_name }}
                          </div>
                          <div>协议：{{ cItem.network }}</div>
                          &nbsp; &nbsp;
                          <div>地址：{{ cItem.qr_code }}</div>
                        </div>
                      </div>
                    </div>
                    <div><el-button @click="_suodingrizhi(item)">锁定日志</el-button> <el-button @click="_huikuan(item)">交易中心付款</el-button>
                      </div>
                    <div>
                      金额：{{ Number(item.amount) }}&nbsp; &nbsp;{{
                        detailsInfo.sale_currency_code
                      }}
                    </div>
                  </div>

                  <div v-if="item.pamentDetails && item.pamentDetails.length > 0">
                    <div v-for="(detail, dIndex) in item.pamentDetails" :key="'detail' + dIndex" class="itembox">
                      <div class="item-li">
                        <div v-if="!detail.payment_customer_name">
                          付款人：{{ detail.payment_client_id || "" }}
                          {{ detail.payment_tenant_name }}
                          {{ detail.payment_store_name }}
                          <text v-if="detail.bank_card_type_code == 'BankCard'">({{ detail.bank_card_number }})</text>
                        </div>
                        
                        <div v-else class="fukuanrenhover"  @click="_customernamedate(detail.payment_customer_id)">
                          付款人：{{ detail.payment_customer_name || "" }}(
                          {{ detail.payment_customer_id }})
                          <text v-if="detail.bank_card_type_code == 'BankCard'">({{ detail.bank_card_number }})</text>
                        </div>
                           <!-- <div><el-button @click="_suodingrizhi(detail)">锁定日志</el-button> <el-button @click="_huikuan(detail)">交易中心付款</el-button> </div> -->
                        <div>
                          金额：{{ Number(detail.amount)
                          }}{{ detail.currency_name }}
                        </div>
                      </div>
                      <div class="item-li">
                        <div>时间：{{ detail.payment_time }}</div>
                        <div>状态：{{ detail.statusName }}</div>
                      </div>
                      <div class="item-li attachBtn">
                        <div class="attachment">
                          <AttachmentPreview :uploaded="detail.attachments" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 客户信息弹窗 -->
    <el-dialog title="客户信息" :visible.sync="custormdialogVisible" width="80%">
      <div style="display: flex; flex-wrap: wrap;">
        <div style="width: 50%;"><el-button @click="printfmasterdata">打印信息</el-button> </div>
        <div style="width: 50%;">
          <!-- <el-button @click="printfmasterpictur">打印图片</el-button> -->
        </div>
        <div style="width: 100%;" class="kehu">
          <div style="display: flex; flex-direction: column;">

            <div v-for="(contact, index) in kehuxinxi2" :key="index" style="display: flex;">
              <span> {{ getContactTypeName(contact.K) }}:</span>
              <span> {{ contact.V }}</span>
            </div>
            <div v-for="(item, index) in certificateInfo" :key="index"
              style="display: flex; flex-wrap: wrap; align-items: center;">
              <div class="label">{{ item.label }}</div>
              <div v-if="item.label == ''" style="margin-top: 30px;">
                <img v-if="item.value" :src="getImageUrl(item.value)" alt="Image1"
                  style="width: 300px;height: 200px; " />
                <span v-else>Loading...</span>
              </div>
              <!-- 其他值的处理 -->
              <div class="value" style="align-items: center;" v-else>
                : {{ convertFieldValue(item) }}
              </div>
            </div>

          </div>
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="custormdialogVisible = false">取 消</el-button>

      </span>
    </el-dialog>

    <!-- 附件弹窗 -->
   
    <el-dialog title="附件信息" :visible.sync="fujiandialogVisible" width="80%">
      <div id="app">
        <div v-for="(item, index) in fujiandata" :key="index"  class="region" style="border-bottom:2px solid black" :class="{shanchu:item.delete_flag}">
          <div class="remark" >
            <p> 备注: {{ item.remark || '暂无备注' }}</p>
          </div>
          <div class="image-gallery" v-if="item.file_key">
  
              <FujianTupian :uploaded="item.file_key" />
          </div>
            <div style="display:flex; justify-content:space-between;">
                   <div>
                       <p>创建人:{{ item.create_user_nick }}</p>
                       <p>创建时间:{{ item.create_time }}</p>
                   </div>
                   <div v-if="item.delete_flag">
                      <p>删除人:{{ item.delete_user_nick }}</p>
                      <p>删除时间:{{ item.delete_time }}</p>
                  </div>
            </div>
       
            <el-button v-if="!item.delete_flag" @click="deleteRegion(item)" type="danger">删除此区域 </el-button>
      
            
        </div>
      </div>

    </el-dialog>
   <!-- 锁定日志弹窗 -->
    <el-dialog title="锁定日志" :visible.sync="suodingrizhiVisible" >
        <tempalte>
          <el-table :data="suodingrizhishuzu" height="550" border style="width: 100%">
              <el-table-column align="center" prop="last_lock_client_id" label="平台">
              </el-table-column>
              <el-table-column prop="last_lock_tenant_name" label="商家" align="center">
              </el-table-column>
              <el-table-column align="center" prop="last_lock_user_nick" label="用户">
              </el-table-column>
              <el-table-column align="center" prop="last_lock_time" label="时间">
              </el-table-column>
              <el-table-column align="center" prop="lock_type" label="状态">
              </el-table-column>
            </el-table>
        </tempalte>
    </el-dialog>
   <!-- 汇款交易中心 -->
    <el-dialog title="交易中心汇款" :visible.sync="huikuanVisible">
        <template>
          <h3>付款信息</h3>
          <el-table :data="payment" height="550" border style="width: 100%">
              <el-table-column align="center" prop="payment_client_id" label="平台"></el-table-column>
              <el-table-column prop="payment_tenant_name" label="商家" align="center"></el-table-column>
              <el-table-column align="center" prop="payment_store_name" label="店铺"></el-table-column>
              <el-table-column align="center" prop="payment_user_nick" label="操作人"></el-table-column>
              <el-table-column align="center" prop="payment_time" label="时间"></el-table-column>
              <el-table-column align="center" prop="amount" label="金额"></el-table-column>
              <el-table-column align="center" prop="currency_name" label="币种"></el-table-column>
              <el-table-column align="center" prop="busi_type" label="付款类型">
                 <template slot-scope="scope">
                     <span>{{ formatBusiType(scope.row.busi_type) }}</span>
                  </template>
              </el-table-column>
              <el-table-column align="center" prop="payment_customer_name" label="客户"></el-table-column>
              <el-table-column align="center" prop="status" label="状态">
                <template slot-scope="scope">
                     <span>{{ formatBusiType(scope.row.status) }}</span>
                  </template>
              </el-table-column>
            </el-table>
        </template>
        <h3>付款中信息</h3>
          <el-table :data="prepayment" height="200" border style="width: 100%">
              <el-table-column align="center" prop="payment_client_id" label="平台"></el-table-column>
              <el-table-column prop="payment_tenant_name" label="商家" align="center"></el-table-column>
              <el-table-column align="center" prop="payment_store_name" label="店铺"></el-table-column>
              <el-table-column align="center" prop="payment_user_nick" label="操作人"></el-table-column>
              <el-table-column align="center" prop="payment_time" label="时间"></el-table-column>
              <el-table-column align="center" prop="amount" label="金额"></el-table-column>
              <el-table-column align="center" prop="currency_name" label="币种"></el-table-column>
              <el-table-column align="center" prop="busi_type" label="付款类型">
                 <template slot-scope="scope">
                     <span>{{ formatBusiType(scope.row.busi_type) }}</span>
                  </template>
              </el-table-column>
              <el-table-column align="center" prop="payment_customer_name" label="客户"></el-table-column>
              <el-table-column align="center" prop="status" label="状态">
                <template slot-scope="scope">
                     <span>{{ formatBusiType(scope.row.status) }}</span>
                  </template>
              </el-table-column>
            </el-table>
        <template>

        </template>
    </el-dialog>
   <!-- 客户流水 -->
    <el-dialog title="客户流水" :visible.sync="shuliangVisible">
        <el-table :data="shuliangdata" height="500" border style="width: 100%">
          <el-table-column prop="create_time" label="时间" ></el-table-column>  
                <el-table-column prop="currency_name" label="币种" ></el-table-column>  
                <el-table-column prop="amount" label="金额" >
                    <template slot-scope="scope">
                        <span>{{ formatAmount(scope.row.amount) }}</span>
                    </template>
                </el-table-column>  
                <el-table-column prop="balance" label="余额">
                    <template slot-scope="scope">
                        <span>{{ formatAmount(scope.row.balance) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
        <el-pagination  :page-size="kehuliushuiinfo.pageSize" layout="prev, pager, next"  :total="kehuliushuiinfo.total" @current-change="kehuliushuihandleCurrentChange">
        </el-pagination>
    </el-dialog>
  </div>
</template>

<script>

import * as XLSX from "xlsx";
import AttachmentPreview from "@/components/AttachmentPreview.vue";
import FujianTupian from "@/components/jiaoyidanfujiantupian.vue";


 export default {
         components: { AttachmentPreview,FujianTupian },
      data() {
               return {
                kehuliushuiinfo:{
                  pageIndex: 1,
                pageSize: 10,
                pageCount: 0,
                total: 0
                },//客户流水分页
                shuliangdata: [],//客户流水数据
                 shuliangVisible: false,//客户流水弹窗的控制
                payment:[],//付款数据
                prepayment:[],//预付款数据
                huikuanVisible: false,//交易中心汇款弹窗的控制
                suodingrizhishuzu: [], //锁定日志数据
                suodingrizhiVisible: false,//锁定日志弹窗的控制

        srcList:[],
      kehuid:"",
      fujiandata: [], //附件数据
      fujiandialogVisible: false,//附件弹窗的控制

      certificateInfo: [], // 初始化为空数组，待请求后填充客户信息展示
      imageUrls: {}, // 用于存储图片 URL 的缓存
      kehuxinxi2: [],
      minzuzidian: [],//性别编号
      xingbiezidian: [],//性别字典
      lianxifangshizidian: [],//客户字典
      kehuxinxi: {},
      custormdialogVisible: false,//客户信息弹窗的控制

      form: {},
      DingDandanhao: "",//订单号
      customerNo: "",
      //websocket 连接对象
      connection: null,
      //websocket 状态
      connection_status: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const now = new Date();
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const now = new Date();
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              const start = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const now = new Date();
              const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
              const start = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate(), 0, 0, 0);
              picker.$emit("pick", [start, end]);
            }
          }
        ],
        },
        value2: "",
        detailsWindow: false,
      detailsInfo: {},
      //查询条件
      queryInfo: {
        // 当前页数
        pageIndex: 1,
        // 每页显示多少数据
        pageSize: 200,
      },
      total: 0,
      setTime: this.getTimestampAtStartOfDay(),
      etTime: this.getEndOfDayTimestamp(),
      // 获取的列表数据
      tableData: [],
      tenantPopover: '',
      storePopover: [],
      statusPopover: '',
      pricePopover: "",
      mairupricePopover: "",
      maichupricePopover: '',
      mairubizhongPopover: '',
      maichubizhongPopover: '',
      jiaoyileixingPopover: '',
      tenantList: [],
      tenantInfo: {},
      storeList: [],
      storeInfo: { store_id: "", store_name: "全部" },
      statusList: [],
      priceList: ["0-1000", "1000-5000", "5000-10000", "10000-50000"],
      maichubizhongList: ['卖出', '买入'],
      mairubizhongList: [],

      jiaoyileixingList: [
        { name: "现金对现金", type: "Cash2Cash" },
        { name: "现金对汇款", type: "Cash2Trans" },
        { name: "余额对现金", type: "Surplus2Cash" },
        { name: "余额对汇款", type: "Surplus2Trans" },
      ],


    };
  },
  mounted() {

    // this.getkehuzidianList();
    this.getStatusList();
    this.getTenantList();
    // this.getData();
    // 获取所有商家和店铺
   
  },
  methods: {
  //  ------------------------------
  // 客户流水
   golookaccount(e){
    this.$http.get("/api/CustomerAccount/GetFlow", {
                params:{
                    storeId:e.store_id,
                    currency:e.buy_currency_id,
                    CustomerID:e.customer_id,
                    orderByBusinessTime:false,
                    pageIndex:this.kehuliushuiinfo.pageIndex,
                    pageSize:this.kehuliushuiinfo.pageSize,
                },
            }).then((res) => {
                if (res.data.code !== 1) return this.$message.error(res.message);
                this.shuliangdata = res.data.data;
                this.kehuliushuiinfo = res.data.pageInfo;
                this.shuliangVisible = true;
            });
   },
  //  客户流水分页
  kehuliushuihandleCurrentChange(val) {
    this.kehuliushuiinfo.pageIndex = val;
    this.golookaccount(this.detailsInfo);
  },

    // 字典展示值
    formatBusiType(busiType) {
      switch (busiType) {
        case 'receipt':
          return '收款单方式付款';
        case 'store-account-payment':
          return '店铺账户方式付款';
          
        case 'delete':
          return '删除';
          case 'agree':
          return '同意';
          case 'refuse':
          return '拒绝';
          case 'wait_confirm':
          return '待确认';
          case 'wait_notify':
          return '待通知';
          case 'pre_pay':
          return '付款中';
        default:
          return '未知付款类型'; // 或者直接返回 busiType
      }
    },
  // -------------------------------------------------------------------------
  // 获取汇款
  _huikuan(e){
    this.$http.post("/api/Center/GetStoreExchangeDetailCenterPayment", {
      data: e.store_exchange_detail_id,
      type:"exchange-detail",
      tenant_id:this.detailsInfo.tenant_id,
    }).then((res) => {
     console.log(res.data,"汇款");
      this.huikuanVisible = true;
      this.payment = res.data.payment;
      this.prepayment = res.data.prepayment;
    });
  },

  // -------------------------------------------------------------------------
  // 锁定日志
  _suodingrizhi(e){
      console.log(e.store_exchange_detail_id  );
      this.$http.post("api/Center/StoreExchangeDetailLockLog", {
        data: e.store_exchange_detail_id,
        type:"exchange-detail",
        tenant_id:this.detailsInfo.tenant_id,
      }).then((res) => {
          if (res.data.code !== 1) return this.$message.error(res.data.message);

          console.log(res.data.data,"锁定日志");
          this.suodingrizhiVisible = true;
          this.suodingrizhishuzu = res.data.data;

          
      });
  },



    async  downloadFilestupian(fileKey) {
  const fileKeys = fileKey.split(','); // 将 file_key 字符串分割成数组
  const imageUrls = []; // 用于存放图片 URL 的数组
  
  // 循环请求每个文件
  for (const key of fileKeys) {
    try {
      const response = await this.$http.get("FileUpload/GetBase64", {
        params: { filekey: key },  // 请求参数
      });

      // 解析 base64 字符串并转换为 Blob 对象
      const binaryData = atob(response.data.replace(/^data:image\/\w+;base64,/, ""));
      const arrayBuffer = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        arrayBuffer[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], { type: "image/jpeg" }); // 根据实际图片格式调整 MIME 类型

      // 生成一个 URL 来显示图片
      const imageUrl = URL.createObjectURL(blob);
      imageUrls.push(imageUrl); // 将图片 URL 添加到数组

    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }
   console.log(imageUrls,"所有图片的URL数组");
   
  return imageUrls; // 返回所有图片的 URL 数组
},


        // 删除指定区域
        deleteRegion(index) {
        console.log(index,"要删除的区域是");
        this.$http.post("/StoreExchange/RemoveAttachment", {
          store_exchange_attachment_id: index.store_exchange_attachment_id
     
        }).then((res) => {
        
          if (res.data.code == 1) {
        this.$message.success("删除成功");

        this.showfujianDialog(index);
      } else {
        this.$message.error(res.data.message);
      }
        });
        
    },
    //附件信息弹窗
    showfujianDialog(e) {
        this.kehuid = e.store_exchange_id
      this.$http.get("StoreExchange/GetAttachment", {
        params: {
          storeExchangeId: this.kehuid
        }
      }).then((res) => {
        if (res.data.code == 1) {
           this.fujiandata = res.data.data;
           this.fujiandialogVisible = true;
        } else {
      return   this.$message.error(res.data.message);
        }
        
       
        
      })


    },

    //打印身份证信息
    printfmasterdata() {
      // 获取要打印的区域
      var kehuPrint = document.querySelector('.kehu').innerHTML;

      // 创建一个新的窗口用于打印
      var printWindow = window.open('', '_blank', 'width=1920,height=1080');

      // 在新窗口中写入内容
      printWindow.document.open();
      printWindow.document.write(`
<html>
<head>
  <title>打印区域</title>
  <style>
    /* 设置打印背景为白色 */
    body {
      background-color: white;
      font-family: Arial, sans-serif;
      margin: 20px;
      color: black;
    }
    img :neth-of-type(3){
    width: 200px;
    height: 200px;
    }

   
  </style>
</head>
<body>
  <div class="detailsList">${kehuPrint}</div>
</body>
</html>
`);


      printWindow.document.close();

      // 确保窗口打印时显示完整内容
      printWindow.print();
    },


    // 根据 K 查找对应的名称
    getContactTypeName(id) {
      if (id == null) return [];
      const type = this.lianxifangshizidian.find(item => item.id === id);
      if (type) {
        const nameObj = JSON.parse(type.name);
        return nameObj[0].V; // 返回 "手机号码" 或其他类型名
      }
      return "未知类型";
    },
    // 根据性别 ID 查找并返回性别名称

    // 获取客户字典
    getDictionaryItem(code) {
      return this.$http.get("/api/Archives/GetDictionaryItem", {
        params: {
          code: code,
        }
      }).then(res => res.data.data);
    },


    // 获取客户资料
    _customernamedate(e) {
      let queryString = `?no=${e}`;
      // this.$router.push({ path: '/home/UserProfile', query: { no:e } });
      window.open(`/home/UserProfile${queryString}`, "_blank", 'width=1920,height=1080');
      // window.open("/home/tradingTicket","_blank",'width=1920,height=1080',true);
  
      // this.$http.get("/api/Archives/GetSimpleArchives", {
      //   params: {
      //     no: e,
      //   },

      // }).then((res) => {


      //   if (res.data.data.credentiales[0] == null) {
      //     this.$message.error('该客户没有证件信息');
          
      //   } else {
      //     this.zhengjianxinxi = res.data.generalStructureTypes[0].propertyes;


      //     this.kehuxinxi2 = JSON.parse(res.data.data.contactes[0].contacts);
      //     this.kehuxinxi = JSON.parse(res.data.data.credentiales[0].value);
      //     const result = this.displayCertificateInfo(this.kehuxinxi, this.zhengjianxinxi);

      //     this.certificateInfo = result;
      //     console.log(this.certificateInfo, '证件信息显示');
      //     this.custormdialogVisible = true;

      //   }

      // });

    

    },

    // 函数: 根据证件信息和字典表显示对应值
    displayCertificateInfo(certificateInfo, dictionary) {
      // 遍历证件信息
      return certificateInfo.map(item => {

        // 查找字典表中对应的项
        const dictItem = dictionary.find(dict => dict.id === item.Id);
        if (dictItem) {
          const languagePack = JSON.parse(dictItem.language_pack);

          const id = item.Id; // 证件类型 ID
          const displayValue = languagePack.find(lang => lang.K === 'zh-CN').V; // 获取中文显示值
          if (displayValue == "证件其他图片") {
            return {}
          } else if (displayValue == "证件正面图片") {
            return {
              label: '',
              id: id,    // 证件类型 ID
              value: item.Value || '未填写'  // 证件信息的值
            };
          } else if (displayValue == "证件背面图片") {
            return {

              label: "",
              id: id,    // 证件类型 ID
              value: item.Value || '未填写'  // 证件信息的值
            }
          }
          else if (displayValue == '证件头像图片') {
            return {

            }
          }
          else {
            return {
              label: displayValue,    // 展示类型
              id: id,    // 证件类型 ID
              value: item.Value || '未填写'  // 证件信息的值
            };
          }

        } else {
          return {

          };
        }
      });
    },
    convertFieldValue(field) {

      // 根据 label 判断是否需要转换
      if (field.label === "性别") {
        const sexObj = this.xingbiezidian.find(item => item.id == field.value);
        if (sexObj) {
          // 解析 'name' 字段并返回对应的性别名称
          const nameObj = JSON.parse(sexObj.name);
          return nameObj[0].V;  // 返回 '男' 或 '女'
        }
        return '';

      } else if (field.label === "民族") {
        const nationObj = this.minzuzidian.find(item => item.id == field.value);
        if (nationObj) {

          const nameObj = JSON.parse(nationObj.name);
          return nameObj[0].V;
        }
        return '';

      } else {
        return field.value; // 对其他字段返回原值
      }

    },
    // 获取图片的 URL（单个图片）
    //eslint-disable-next-line
    getImageUrl(imageId) {
      // 如果缓存中已有 URL，直接返回缓存
      if (this.imageUrls[imageId]) {
        return this.imageUrls[imageId];
      }

      // 请求图片并缓存
      this.$http.get("FileUpload/GetBase64", {
        params: { filekey: imageId }
      })
        .then(response => {
          // 解析返回的 base64 字符串
          const binaryData = atob(response.data.replace(/^data:image\/\w+;base64,/, ""));
          const arrayBuffer = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            arrayBuffer[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: "image/jpeg" }); // 根据实际图片类型调整 MIME
          const imageUrl = URL.createObjectURL(blob);

          // 缓存图片 URL
          this.$set(this.imageUrls, imageId, imageUrl);
        })
        .catch(error => {
          console.error("Error downloading the image:", error);
          this.$set(this.imageUrls, imageId, "https://via.placeholder.com/150"); // 如果下载失败，返回占位图
        });

      // 返回缓存或占位图
      return this.imageUrls[imageId] || "https://via.placeholder.com/150";
    },



    // 打印事件 detailsList
  //   printfcustomerName() {

  //     // 获取要打印的区域
  //     var detailsToPrint = document.querySelector('.detailsPop').innerHTML;

  //     // 创建一个新的窗口用于打印
  //     var printWindow = window.open('', '_blank', 'width=1920,height=1080');

  //     // 在新窗口中写入内容
  //     printWindow.document.open();
  //     printWindow.document.write(`
  //   <html>
  //     <head>
  //       <title>打印区域</title>
  //       <style>
  //         /* 设置打印背景为白色 */
  //         body {
  //           background-color: white;
  //           font-family: Arial, sans-serif;
  //           margin: 20px;
  //           color: black;
  //         }

  //          .detailsList {
  //           width: 1200px;
  //           box-sizing: border-box;
  //           padding: 10px 20px;
  //           background-color: #fff;
  //           border-bottom: 2px solid #000;
  //         }

  //         .item-info {
  //           border-bottom: 2px solid #000;
  //         }

  //         .item-title {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //           padding: 5px 0;
  //         }

  //         .item-content {
  //           padding: 5px 0;
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //         }

  //         .header-title {
            
           
  //           box-sizing: border-box;
  //         }

  //         .item-ul {
  //           margin: 5px 0;
  //           border-bottom: 1px solid #8e8c8c;
  //         }

  //         .itembox {
  //           border-top: 1px dashed #7a7a7a;
  //         }

  //         .item-li {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //           padding: 5px 0;
  //         }

  //         .paymentCode {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //         }

  //         .paymentCode-title {
  //           width: 130px;
  //         }

  //         .paymentQrCode-box {
  //           display: flex;
  //           align-items: center;
  //         }

  //         .paymentQrCode {
  //           width: 70px;
  //         }

  //         .component-container {
  //           width: 40%;
  //         }

  //         .attachment {
  //             display: flex;
  //             align-items: center;
  //             flex-wrap: wrap;
  //             width: 100%;
             
  //           }
               
  //         img{
  //           width: 100px;
  //           height: 100px;
  //         }
  //         .u-button {
  //           margin: 0 10px;
  //         }
  //       </style>
  //     </head>
  //     <body>
  //       <div class="detailsList">${detailsToPrint}</div>
  //     </body>
  //   </html>
  // `);


  //     printWindow.document.close();

  //     // 确保窗口打印时显示完整内容
  //     printWindow.print();
  //   },
    getSelectedRows() {
      const selectedRows = this.$refs.multipleTable.selection;
      return selectedRows;
    },
    handleSendSelectedData() {
      const selectedRowshide = this.getSelectedRows();
      const dataArray = selectedRowshide.map(row => (
        row.hide_flag = true,
        {
          // 根据你的实际需求，提取每行需要发送的数据字段组成新的对象
          store_exchange_id: row.store_exchange_id,
          hide_flag: row.hide_flag,
          //...其他需要发送的字段
        })
      );
      this.handleHideFlagChange(dataArray);
    },

    async handleHideFlagChange(row) {
      const { data: res } = await this.$http.post("StoreExchange/SetHideFlag", {
        items: row
      });
      if (res.code == 1) {
        this.$message.success("修改成功");

        this.getData();
      } else {
        this.$message.error(res.message);
      }
    },
    async handleHideFlagChange2(row) {
      const { data: res } = await this.$http.post("StoreExchange/SetHideFlag", {
        items: [{
          store_exchange_id: row.store_exchange_id,
          hide_flag: row.hide_flag
        }]
      });
      if (res.code == 1) {
        this.$message.success("修改成功");

        this.getData();
      } else {
        this.$message.error(res.message);
      }
    },
    handleCustomerChange() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },

    async exportExcel() {
      const { data: res } = await this.$http.post("StoreExchange/Query", {

        buyCurrencyID: this.mairubizhongPopover,
        saleCurrencyID: this.maichubizhongPopover,
        minBuyAmount: this.mairupricePopover,
        exchangeType: this.jiaoyileixingPopover,
        minSellAmount: this.maichupricePopover,
        tenant: this.tenantPopover,
        storeList: this.storePopover,
        st: this.setTime,
        et: this.etTime,
        customer: this.customerNo,
        status: this.statusPopover,

        pageIndex: this.queryInfo.pageIndex,
        pageSize: this.total,

      });
      if (res.code == -1) return this.$message.error(res.message);
      let dataList = [];

      if (Array.isArray(res.data)) {
        dataList = res.data
          .map((item) => {
            if (item && item.status !== "delete" && !item.hide_flag) {
              return {
                店铺: item.store_name || "",
                单号: item.store_exchange_no || "",
                客户:
                  (item.customer_name || "") +
                  "(" +
                  (item.customer_id || "") +
                  ")",
                买入: (item.buy_amount || 0) * 1,
                买入币种: item.buy_currency_name || "",
                卖出: (item.sale_amount || 0) * 1,
                卖出币种: item.sale_currency_name || "",
                汇率: (item.rate || 0) * 1,
                汇率倒数: (item.rate_reciprocal || 0) * 1,
                交易类型: item.exchange_type_name || "",
                时间: item.create_time || "",
                状态: item.status_name || "",
              };
            }
          })
          .filter(Boolean); // 过滤掉返回值为 undefined 的项
      } else {
        dataList = [];
      }
      let storeName = this.storeInfo.store_name;
      let time = this.setTime + "至" + this.etTime;
      // 将data数组转换为工作表
      const ws = XLSX.utils.json_to_sheet(dataList);

      // 创建一个新的工作簿
      const wb = XLSX.utils.book_new();

      // 将工作表添加到工作簿中
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // 生成Excel文件
      XLSX.writeFile(wb, storeName + "-交易单-" + time + ".xlsx");
    },

    // sendMessage() {
    //     if (this.connection) {
    //         this.connection.send('SendMessage', 'Hello, Server!');
    //     }
    // },
    getTimestampAtStartOfDay() {
      var now = new Date();
      now.setHours(0, 0, 0, 1); // 设置为当天的00:00:01
      var year = now.getFullYear();
      var month = ("0" + (now.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
      var day = ("0" + now.getDate()).slice(-2);
      var hours = ("0" + now.getHours()).slice(-2);
      var minutes = ("0" + now.getMinutes()).slice(-2);
      var seconds = ("0" + (now.getSeconds() + 1)).slice(-2);

      return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
    },
    getEndOfDayTimestamp() {
      const now = new Date();
      now.setHours(23, 59, 59, 999); // 将时、分、秒设置为23、59和59，毫秒设置为999以确保总是最后一秒
      var year = now.getFullYear();
      var month = ("0" + (now.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
      var day = ("0" + now.getDate()).slice(-2);
      var hours = ("0" + now.getHours()).slice(-2);
      var minutes = ("0" + now.getMinutes()).slice(-2);
      var seconds = ("0" + now.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}-${minutes}-${seconds}`;
    },
    getData2() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    async getData() {
      const { data: res } = await this.$http.post("StoreExchange/Query", {

        buyCurrencyID: this.mairubizhongPopover,
        saleCurrencyID: this.maichubizhongPopover,
        minBuyAmount: this.mairupricePopover,
        exchangeType: this.jiaoyileixingPopover,
        minSellAmount: this.maichupricePopover,
        tenant: this.tenantPopover,
        storeList: this.storePopover,
        st: this.setTime,
        et: this.etTime,
        customer: this.customerNo,
        status: this.statusPopover,

        pageIndex: this.queryInfo.pageIndex,
        pageSize: this.queryInfo.pageSize,

      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.forEach((row) => {
        if (row.hide_flag) {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        }
      });
      this.tableData = res.data;
      this.total = res.pageInfo.total;
    },
   async _getdanhao(){
       if(this.tenantPopover==''){
          return this.$message.error('请选择商家'); 
      }
      const { data: res } = await this.$http.post("/StoreExchange/QueryByNo",{
          storeExchangeNo:this.DingDandanhao,
          tenant:this.tenantPopover,
          pageIndex: this.queryInfo.pageIndex,
          pageSize: this.queryInfo.pageSize,
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.forEach((row) => {
        if (row.hide_flag) {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        }
      });
      this.tableData = res.data;
      this.total = res.pageInfo.total;
        
    },
    // 获取状态列表
    async getStatusList() {
      const { data: res } = await this.$http.get("StoreExchange/GetStatus");


      this.statusList = res.data;

    },

    // 获取商家列表
    getTenantList() {
      // 从 localStorage 中获取 'tenants' 的值
      const tenantsJson = localStorage.getItem("loginTenantsInfo");
      let tenants = [];
      if (tenantsJson) {
        // 将 JSON 字符串解析为 JavaScript 对象
        tenants = JSON.parse(tenantsJson);

        // 使用解析后的对象
        console.log("使用解析后的对象", tenants);
      } else {
        console.log("No tenants data found in local storage.");
      }
      this.tenantList = tenants;
      // this.tenantInfo = tenants[0];
      // this.getStoreList();
    },

    // 获取门店信息
    _getstoreData() {
      this.storePopover = []
      this.$http.get("v1/Store/GetStoreInfoByTenant", {
        params: { tenant: this.tenantPopover },
      }).then(({ data: res }) => {

        this.storeList = res.data;

      });
    },
    // 获取店铺列表
    async getStoreList() {
      const { data: res } = await this.$http.get(
        "v1/Store/GetStoreInfoByTenant",
        {
          params: { tenant: this.tenantInfo.tenant_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      this.storeList = res.data;


      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    // 店铺选择
    handleStoreChange(item) {
      // 判断item是否为空
      if (item.length == 0) return;

      this._getbizhongList(item[0]);

    },
    // 币种数据
    _getbizhongList(e) {
      this.$http.get("/StoreCurrency/GetEnable", {
        params: {
          store: e,
          tenant: this.tenantPopover
        }
      }).then((res) => {
        if (res.code == -1) return this.$message.error(res.message);
        this.mairubizhongList = res.data.data;

      });
    },

    // 金额选择
    handlePriceChange(e) {
      this.priceInfo.store_name = e;
      this.queryInfo.pageIndex = 1;
      this.getData();
      this.pricePopover = false;
    },

    // 日期选择
    handleDateChange(e) {
      if (e) {
        let startDateStr = e[0].split(' ')[0];
        let endDateStr = e[1].split(' ')[0];
        let newStartDateStr = `${startDateStr} 00:00:00`;
        let newEndDateStr = `${endDateStr} 23:59:59`;

        this.value2 = [newStartDateStr, newEndDateStr];


        this.setTime = newStartDateStr;
        this.etTime = newEndDateStr;

      } else {
        this.setTime = this.getTimestampAtStartOfDay();
        this.etTime = this.getEndOfDayTimestamp();
      }

    },
    query() {
      this.queryInfo.pageIndex = 1;
      this.getData();
    },
    // 监听 pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.pageIndex = newSize;
      this.getData();
    },
    async showEditDialog(row) {
       this.kehuliushuiinfo.pageIndex = 1;
      const { data: res } = await this.$http.get("StoreExchange/GetDetail", {
        params: {
          ExchangeID: row.store_exchange_id,
        },
      });
      if (res.code == -1) return this.$message.error(res.message);
      res.data.toBePaidAmount = 0;
      if (res.data && res.data.details && res.data.details.length > 0) {
        res.data.details.forEach((item) => {
          res.data.toBePaidAmount += Number(item.wait_amount);
          if (item.pamentDetails && item.pamentDetails.length > 0) {
            item.pamentDetails.forEach((cItem) => {
              if (cItem.status == "agree") {
                cItem.statusName = "已同意收款";
              } else if (cItem.status == "wait") {
                cItem.statusName = "待确认收款";
              } else {
                cItem.statusName = "已拒绝收款";
              }
              cItem.uploadPath = [];
              if (cItem.attachments && cItem.attachments.length > 0) {
                cItem.attachments.forEach((attach) => {
                  cItem.uploadPath.push(attach.file_key);
                });
              }
            });
          }
        });
      }
      this.detailsInfo = res.data;
      this.detailsWindow = true;
    },
    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);

      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    async showAttachment() {
      const { data: res } = await this.$http.get(
        "v1/Store/GetStoreInfoByTenant",
        {
          params: { storeExchangeId: this.detailsInfo.store_exchange_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res);
      this.$utils.showToast(res.message);
      if (res.code != 1) return;
      if (res.data && res.data.length > 0) {
        this.appendList = res.data;
        this.appendShow = true;
      } else {
        this.$utils.showToast("未添加附件！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shanchu{
  background-color: #f56c6c;
}
.search-box {
  display: flex;
  flex-wrap: wrap;

  align-content: space-around;



  .exportExcelBtn {
    margin: 0 50px;
  }
}

.popover-content {
  width: 100%;
  max-height: 600px;
  // overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;

  >div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}

.el-table {
  /deep/ .s_s {
    background: #228b22;
    color: white;
  }

  /deep/ .t_s {
    background: #6b8e23;
    color: white;
  }
}

.custom-table ::v-deep .el-table__cell {
  font-size: 16px !important;
}

.detailsPop {
  font-size: 16px;

  /deep/.u-transition {
    z-index: 996 !important;
  }

  .detailsList {
    width: 1200px;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: 2px solid #000;

    .item-info {
      border-bottom: 2px solid #000;

      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
      }

      .item-title :hover {
        cursor: pointer;
        color: #007aff;
      }

      .item-content {
        padding: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >view {
          >view {
            padding: 2px 0;
          }
        }
      }
    }

    .header-title {
      max-height: 500px;
      overflow: auto;
      box-sizing: border-box;

      .item-ul {
        margin: 5px 0;
        border-bottom: 1px solid #8e8c8c;

        .itembox {
          //虚线
          border-top: 1px dashed #7a7a7a;
        }

        .item-li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 5px 0;
          
         .fukuanrenhover:hover {
          cursor: pointer;

            color: #007aff;
          

          }

          .paymentCode {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .paymentCode-title {
              width: 130px;
            }

            .paymentQrCode-box {
              display: flex;
              align-items: center;
            }

            .paymentQrCode {
              width: 70px;
            }

            .component-container {
              width: 40%;
            }
          }
        }

        .attachBtn {
          .attachment {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 0;

            >div {
              display: flex;
              align-items: center;
            }
          }
        }

        .button {
          .u-button {
            margin: 0 10px;
          }
        }
      }
    }
  }
}


/deep/.el-checkbox__inner {
  width: 50px;
  /* 调整宽度 */
  height: 50px;
  /* 调整高度 */
}

/deep/.el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  border: 1px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 24px;
  left: 8px;
  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);
  width: 24px;
  transition: transform .15s ease-in .05s;
  transform-origin: center;
}

/* 附件弹窗 */
.region {
  margin-bottom: 20px;

}

.remark {
  font-size: 14px;
  color: #333;
}

.image-gallery {
  display: flex;
  overflow-x: auto;
  margin-top: 10px;
}

.image-item {
  flex-shrink: 0;
  margin-right: 10px;
}

.image {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.buttondef {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}

.buttondef:hover {
  background-color: #d32f2f;
}
</style>
