import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/LoginView.vue";
import Home from "../views/HomeView.vue";
import BalanceList from "../views/statistics/balanceList.vue";
import AccountBalance from "../views/statistics/accountBalance.vue";
import TradingTicket from "../views/transaction/tradingTicket.vue";
import TrackingAddress from "../views/transactionHistory/trackingAddress.vue";
import TransactionHistory from "../views/transactionHistory/transactionHistory.vue";
import DataReport from "../views/dataStatistics/dataReport.vue";
import  UserProfile from "../views/customerManagementModule/UserProfile.vue";
import  transactionAccounting from "../views/transactionAccounting/transactionAccounting.vue"; //往来记账
import customerAccountStatement from "../views/customerManagementModule/customerAccountStatement/customerAccountStatement.vue";//客户账户流水
import assetStatistics from "../views/assetStatisticsOfTradingCenter/assetStatistics/assetStatistics.vue";//交易中心资产统计
import centeraccountFlow from "../views/assetStatisticsOfTradingCenter/assetStatistics/components/Trade-Center-Account-Flow.vue";//交易中心资产统计
import customerWalletAddress from "../views/customerManagementModule/customerWalletAddress/customerWalletAddress.vue";//客户钱包地址
import storeGrouping from "../views/storeGrouping/storeGrouping.vue"; //店铺分组
import myAssets from "../views/myAssets/myAssets.vue"; //我的资产
import listOfIoTDevices from "../views/systemEquipmentManagement/listOfIoTDevices/listOfIoTDevices.vue"; //我的资产下的物联网设备列表
import currencyStatistics from "../views/dataStatistics/currencyStatistics/currencyStatistics.vue"; //货币统计

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  { path: "/login", component: Login },
  {
    path: "/home",
    component: Home,
    redirect: "/home/accountBalance", // 新增这一行，使得 /home 默认重定向到 /home/accountBalance
    children: [
      {
        path: "balanceList",
        component: BalanceList,
      },
      {
        path: "accountBalance",
        component: AccountBalance,
      },
      {
        path: "tradingTicket",
        component: TradingTicket,
      },
      {
        path: "trackingAddress",
        component: TrackingAddress,
      },
      {
        path: "transactionHistory",
        component: TransactionHistory,
      },
      {
        path: "dataReport",
        component: DataReport,
      },
      {
        path: "userProfile",
        component: UserProfile,
      },
      {
        path: "transactionAccounting",
        component: transactionAccounting,
      },
      {
        path: "customerAccountStatement",
        component: customerAccountStatement,
      },
      {
        path: "assetStatistics",
        component: assetStatistics,
      },
      {
        path: "centeraccountFlow",
        component: centeraccountFlow,
      },
      {
        path: "customerWalletAddress",
        component: customerWalletAddress,
      },
      {
        path: "storeGrouping",
        component: storeGrouping,
       },
       {
        path: "myAssets",
        component: myAssets,
      },
      {
        path: "listOfIoTDevices",
        component: listOfIoTDevices,
      },
      {
        path: "currencyStatistics",
        component: currencyStatistics,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history", // 使用 HTML5 History 模式
  base: "/", // 动态设置基础路径
  routes,
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // 访问登录页，直接放行
  if (to.path === "/login") {
    return next();
  }

  // 获取 token
  const tokenStr = window.localStorage.getItem("token");

  // 没有 token，强制跳转到登录页
  if (!tokenStr) {
    return next("/login");
  }

  // 有 token，继续放行
  next();
});

export default router;
