<template>
  <div class="myAssets">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>统计</el-breadcrumb-item>
      <el-breadcrumb-item>我的资产</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="container-top">
      <div class="left">
        <span class="left-title">选择商家:</span>
        <div class="left-content">
          <el-select @change="handleTenantChange" v-model="tenantPopover" placeholder="请选择">
            <el-option v-for="item in tenantList" :key="item.tenant_id" :label="item.tenant_name"
              :value="item.tenant_id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="right">
        <div class="right-title">选择店铺:</div>
        <div class="right-content">
        <div class="right-item" @click="handleStoreChange(item, index)"
          :class="{ 'red-background': index === activeIndex }" v-for="(item, index) in storeList" :key="index">
          {{ item.store_name }}
        </div>
      </div>
     </div>
    </div> -->
    <div class="container-header">
 <!--  -->


 <div class="search-box">
        <div class="search-item">
          <div>选择商家：</div>
          <el-popover
            placement="bottom"
            width="200"
            v-model="tenantPopover"
            trigger="click"
          >
            <div class="popover-content">
              <div
                v-for="(item, index) in tenantList"
                :key="index"
                @click="handleTenantChange(item)"
              >
                {{ item.tenant_name }}
              </div>
            </div>
            <el-button slot="reference">{{ tenantInfo.tenant_name }}</el-button>
          </el-popover>
        </div>
        <div class="search-item1">
          <div class="selectStore">选择店铺：</div>
          <div class="popover-content1">
            <div
              v-for="(item, index) in storeList"
              :key="index"
              :class="{ 'red-background': index === activeIndex }"
              @click="handleStoreChange(item, index)"
            >
              {{ item.store_name }}
            </div>
          </div>
        </div>
      </div>
<!--  -->
      <el-form :model="forml" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="折算币种">
              <el-select v-model="forml.currentPopover" @change="getbizhongdata" placeholder="请选择">
                <el-option v-for="(item, index) in currentList" :key="index" :label="item.currency_name"
                  :value="item.currency_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button @click="_getstoredetail">刷新</el-button>
          </el-col>
          <el-col :span="6">
            <el-button>保存</el-button>
          </el-col>
          <el-col :span="6">
            <el-button @click="_lishijilu">历史记录</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template>
      <div style="text-align: left;">
        毛利润：资产 + 成本 - 负债：折合港元总和: <span style="font-size: 24px; color: red;">{{formatNumberWithCommas( totalAccounts.toFixed(parseFloat(currentinfo.currency_compute_dot_count))) }}</span>
      </div>
      <template>
        <el-table :data="mergedArray">
          <el-table-column label="总金额" header-align="center">
            <el-table-column prop="currency_name" label="币种"></el-table-column>
            <el-table-column prop="total_balance" label="金额">
              <template slot-scope="scope">
                <span> {{ amountInterception(scope.row.total_balance, scope.row) }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="currency_symbol" label="汇率">
              <template slot-scope="scope">
                <span> {{ exchangeRateAmountCalculation(scope.row.currency_id, scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="currency_symbol" label="折算金额">
              <template slot-scope="scope">
                <span> {{ amountInterception2(scope.row.rate * scope.row.amount, scope.row) }}</span>
              </template>
            </el-table-column>
          </el-table-column>

        </el-table>
      </template>



      <el-tabs v-model="activeName">
        <el-tab-pane label="资产类总额" name="first">
          <template>
            <!-- 总资产类的count金额 -->
            <el-table :data="zhichanData">
              <el-table-column label="资产类总额" header-align="center">
                <el-table-column prop="currency_name" label="币种"></el-table-column>
                <el-table-column prop="total_balance" label="金额">
                  <template slot-scope="scope">
                    <span> {{ amountInterception(scope.row.total_balance, scope.row) }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="currency_symbol" label="汇率">
                  <template slot-scope="scope">
                    <span> {{ exchangeRateAmountCalculation(scope.row.currency_id, scope.row) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="currency_symbol" label="折算金额">
                  <template slot-scope="scope">
                    <span> {{ amountInterception2(scope.row.rate * scope.row.amount, scope.row) }}</span>
                  </template>
                </el-table-column>
              </el-table-column>

            </el-table>
          </template>
          <template>
            <!-- 资产类的每个店铺 -->
            <div class="content">
              <div v-for="(item, index) in StoreCountData[0]" :key="index" class="content-item">
                <template>
                  <el-table :data="item.balances">
                    <el-table-column :label="item.store_name + '-' + item.store_account_name + '-' + item.subject"
                      header-align="center">


                      <el-table-column prop="currency_name" label="币种">

                      </el-table-column>
                      <el-table-column prop="balance" label="金额">
                        <template slot-scope="scope">
                          <span> {{ amountInterception(scope.row.balance, scope.row) }}</span>

                        </template>
                      </el-table-column>
                      <el-table-column prop="currency_symbol" label="汇率">
                        <template slot-scope="scope">
                          <span> {{ exchangeRateAmountCalculation(scope.row.currency_id, scope.row) }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="currency_symbol" label="折算金额">
                        <template slot-scope="scope">
                          <span> {{ amountInterception2(scope.row.rate * scope.row.amount, scope.row) }}</span>
                        </template>
                      </el-table-column>

                    </el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane label="负债类总额" name="second">
          <template>
            <!-- 负债类的count金额 -->
            <el-table :data="fuzaiData">
              <el-table-column label="负债类总额" header-align="center">
                <el-table-column prop="currency_name" label="币种"></el-table-column>
                <el-table-column prop="total_balance" label="金额">
                  <template slot-scope="scope">
                    <span> {{ amountInterception(scope.row.total_balance, scope.row) }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="currency_symbol" label="汇率">
                  <template slot-scope="scope">
                    <span> {{ exchangeRateAmountCalculation(scope.row.currency_id, scope.row) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="currency_symbol" label="折算金额">
                  <template slot-scope="scope">
                    <span> {{ amountInterception2(scope.row.rate * scope.row.amount, scope.row) }}</span>
                  </template>
                </el-table-column>
              </el-table-column>

            </el-table>
          </template>
          <template>
            <!-- 负债类的每个店铺 -->
            <div class="content">
              <div v-for="(item, index) in StoreCountData[1]" :key="index" class="content-item">
                <template>
                  <el-table :data="item.balances">
                    <el-table-column :label="item.store_name + '-' + item.store_account_name + '-' + item.subject"
                      header-align="center">

                      <el-table-column prop="currency_name" label="币种">

                      </el-table-column>
                      <el-table-column prop="balance" label="金额">
                        <template slot-scope="scope">
                          <span> {{ amountInterception(scope.row.balance, scope.row) }}</span>

                        </template>
                      </el-table-column>
                      <el-table-column prop="currency_symbol" label="汇率">
                        <template slot-scope="scope">
                          <span> {{ exchangeRateAmountCalculation(scope.row.currency_id, scope.row) }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="currency_symbol" label="折算金额">
                        <template slot-scope="scope">
                          <span> {{ amountInterception2(scope.row.rate * scope.row.amount, scope.row) }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane label="损益类总额" name="third">
          <template>
            <!-- 损益类的count金额 -->
            <el-table :data="sunyiData">
              <el-table-column label="损益类类总额" header-align="center">
                <el-table-column prop="currency_name" label="币种"></el-table-column>
                <el-table-column prop="total_balance" label="金额">
                  <template slot-scope="scope">
                    <span> {{ amountInterception(scope.row.total_balance, scope.row) }}</span>
                  </template>
                </el-table-column>

                <el-table-column prop="currency_symbol" label="汇率">
                  <template slot-scope="scope">
                    <span> {{ exchangeRateAmountCalculation(scope.row.currency_id, scope.row) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="currency_symbol" label="折算金额">
                  <template slot-scope="scope">
                    <span> {{ amountInterception2(scope.row.rate * scope.row.amount, scope.row) }}</span>
                  </template>
                </el-table-column>
              </el-table-column>

            </el-table>
          </template>
          <template>
            <!-- 负债类的每个店铺 -->
            <div class="content">
              <div v-for="(item, index) in StoreCountData[2]" :key="index" class="content-item">
                <template>
                  <el-table :data="item.balances">
                    <el-table-column :label="item.store_name + '-' + item.store_account_name + '-' + item.subject"
                      header-align="center">

                      <el-table-column prop="currency_name" label="币种">

                      </el-table-column>
                      <el-table-column prop="balance" label="金额">
                        <template slot-scope="scope">
                          <span> {{ amountInterception(scope.row.balance, scope.row) }}</span>

                        </template>
                      </el-table-column>
                      <el-table-column prop="currency_symbol" label="汇率">
                        <template slot-scope="scope">
                          <span> {{ exchangeRateAmountCalculation(scope.row.currency_id, scope.row) }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="currency_symbol" label="折算金额">
                        <template slot-scope="scope">
                          <span> {{ amountInterception2(scope.row.rate * scope.row.amount, scope.row) }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </template>


    <!-- 历史记录弹窗 -->
<template>
  <el-dialog
  title="历史记录"
  :visible.sync="dialogVisible1"
  width="30%"
 >
  <span>这是一段信息</span>
  <historicalRecords :storePopover="storePopover"></historicalRecords>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>
  
</template>


  </div>
</template>
  
<script>
import historicalRecords from './compontens/historicalRecords.vue'

export default {
  name: "myAssets",
  components: {
    historicalRecords,
  },
  computed: {
    // 计算 accounts 的总和

    totalAccounts() {
      return this.mergedArray.reduce((sum, item) => {
        const accounts = parseFloat(item.accounts); // 尝试解析为数字
        if (isNaN(accounts)) {
          console.warn(`Invalid accounts value:`, item.accounts); // 打印无效值用于调试
          return sum; // 跳过无效值
        }
        return sum + accounts; // 累加有效值
      }, 0);
    }

  },

  data() {
    return {
      dialogVisible1: false,// 控制历史记录弹窗的显示隐藏

      activeName: 'first',

      forml: {
        currentPopover: "344",
      },
      currentList: [], // 币种列表
      currentinfo: {}, // 选择币种的对象

      activeIndex: 0,
      tenantPopover: false,
      tenantInfo:{},
      storePopover: "",
      storeList: [], // 店铺列表


      StoreCountData: [], // 一个店铺详情数据
      zhichanData: [], // 一个店铺资产详情数据
      fuzaiData: [], // 一个店铺负债详情数据
      sunyiData: [], // 一个店铺损益详情数据

      huilvdata: [], // 汇率数组

      mergedArray: [], // 合并数组总count

    };
  },
  created() {
    this.tenantList = JSON.parse(localStorage.getItem("loginTenantsInfo"));
    this.tenantInfo = this.tenantList[0];

  },

  mounted() {

    this.getstoredetail();

  },
  methods: {
    // 一进页面就触发的事件
    async getstoredetail() {
      // 获取店铺列表

      await this.handleTenantChange(this.tenantList[0]);
      //  获取币种列表

      await this.getcurrent();

      //  获取汇率
      await this._getobtainExchangeRate();



      // 获取总金额详情

      await this._getstoredetail();
      // 获取客户余额详情
      await this._getkehuyue();

    },

    async _getkehuyue() {
      let data = []
      if (this.storePopover == '') {
        data = this.storeList.filter(
          (item) => item.store_name !== "全部" && item.store_id !== ""
        );
        data = data.map((item) => item.store_id);

      } else {
        data = [this.storePopover];
      }


      const { data: res } = await this.$http.post(
        "/api/CustomerAccount/Sum",
        data,
      );
      if (res.code !== 1) return this.$message.error(res.message);
      console.log(res.data, '客户余额');

      res.data = res.data.map(item => {

        const store = this.StoreCountData[1].find(jtem => jtem.store_id == item.store_id && jtem.subject == '2202');
        if (store) {
          store.balances = item.balances;
        }
      });
      this.fuzaiData = await this.calculateTotalBalances(this.StoreCountData[1]);

      this.mergedArray = await this.calculateTotalAmount(this.zhichanData, this.fuzaiData, this.sunyiData);

      console.log(this.mergedArray[0].accounts, '店铺9-总和');


      // 主动触发重新计算
      this.$forceUpdate();
    },


    // content 的内容
    async _getstoredetail() {
      let data = []
      if (this.storePopover == '') {
        data = this.storeList.filter(
          (item) => item.store_name !== "全部" && item.store_id !== ""
        );
        data = data.map((item) => item.store_id);

      } else {
        data = [this.storePopover];
      }

      const { data: res } = await this.$http.post(
        "/StoreAccount/GetNew",
        {
          store: data,

        });
      if (res.code !== 1) return this.$message.error(res.message);


      this.StoreCountData = this.groupBySubjectClassName(res.data);
      console.log(this.StoreCountData, '店铺2');

      this.zhichanData = await this.calculateTotalBalances(this.StoreCountData[0]);
      this.fuzaiData = await this.calculateTotalBalances(this.StoreCountData[1]);
      this.sunyiData = await this.calculateTotalBalances(this.StoreCountData[2]);

      console.log(this.zhichanData, '店铺3-资产类');
      console.log(this.fuzaiData, '店铺4-负债类');
      console.log(this.sunyiData, '店铺5-损益类');
      this.mergedArray = await this.calculateTotalAmount(this.zhichanData, this.fuzaiData, this.sunyiData);

      console.log(this.mergedArray, '店铺6-总和');

    },


    // 获取币种 - 写死
    async getcurrent() {
      const { data: res } = await this.$http.get(
        "/StoreCurrency/GetEnable",
        {
          params: { tenant: '583181604902539264', store: '611389650812145664' },
          // params: { tenant: "657037274361499648",store: '657037274390859776' },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      console.log(res.data, '币种');
      this.currentList = res.data;
      this.currentinfo = this.currentList.find(item => item.currency_id == this.forml.currentPopover)

    },
    getbizhongdata() {
      this.currentinfo = this.currentList.find(item => item.currency_id == this.forml.currentPopover)

    },
    // 获取店铺
    async handleTenantChange(item) {
      this.tenantInfo = item;

      this.tenantPopover = false;
      const { data: res } = await this.$http.get(
        "v1/Store/GetStoreInfoByTenant",
        {
          params: { tenant: this.tenantInfo.tenant_id },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      res.data.unshift({
        store_id: "",
        store_name: "全部",
      });
      res.data = res.data.filter(item => !item.store_id.includes('608322287283867648'));
      res.data = res.data.filter(item => !item.store_id.includes('629155004615364608'));
      res.data = res.data.filter(item => !item.store_id.includes('644089413139304448'));
      res.data = res.data.filter(item => !item.store_id.includes('644089484933206016'));

      this.storeList = res.data.filter(item => !item.store_name.includes('停用'));
      console.log(this.storeList, '店铺qq');
      this.storePopover = this.storeList[0].store_id;
      console.log(this.storePopover, '店铺id');

    },
    // 店铺切换
  async  handleStoreChange(item, index) {
     this.activeIndex = index;
     this.storePopover = item.store_id;

      await this._getstoredetail();
     await   this._getkehuyue();
    },
    // 获取汇率
    async _getobtainExchangeRate() {
      const { data: res } = await this.$http.get(
        "/api/StoreRate/GetEnable",
        {
          params: { store: '611389650812145664' },
        }
      );
      if (res.code == -1) return this.$message.error(res.message);
      this.huilvdata = res.data;

    },

    // --------------------------- 历史记录 -----------------------------------------------------------------
    _lishijilu(){
      this.dialogVisible1 = true; 
    },
    

    


    // ------------------- 公共事件-----------------------------------------------------------------
    /**
 * 传入一个数组，根据 subject_class_name 属性进行分组
 * @param {Array} accounts - 要分组的数组
 * @returns {Array} - 分组后的数组的数组
 */
    groupBySubjectClassName(arr) {
      // 使用 Map 来存储相同 subject_class_name 的对象数组
      const grouped = arr.reduce((acc, obj) => {
        // 获取当前对象的 subject_class_name
        const key = obj.subject_class_name;

        // 如果 Map 中还没有这个 key，则初始化为一个空数组
        if (!acc.has(key)) {
          acc.set(key, []);
        }

        // 将当前对象添加到对应的数组中
        acc.get(key).push(obj);

        return acc;
      }, new Map());

      // 返回一个包含分组后的数组的数组
      return Array.from(grouped.values());
    },

    //      /**
    //     * 计算每种币种的总余额
    //     *
    //     * @param data 传入的数据数组，每个元素是一个包含 balances 数组的对象
    //     * @returns 返回每种币种的总余额数组，每个元素包含 currency_id, currency_name 和 total_balance 属性
    //     */
    //    calculateTotalBalances(data) {
    //   // 用一个 Map 来存储每种币种的累计信息
    //   const currencyTotals = new Map();

    //   // 遍历传入的数组
    //   data.forEach(item => {
    //     // 遍历每个对象的 balances 数组
    //     item.balances.forEach(balance => {
    //       const { currency_id, currency_name, balance: rawBalance } = balance;

    //       // 确保 balance 是有效数值，如果不是，则默认为 0
    //       const amount = parseFloat(rawBalance) || 0;

    //       // 如果 Map 中还没有这个币种，则初始化
    //       if (!currencyTotals.has(currency_id)) {
    //         currencyTotals.set(currency_id, {
    //           currency_id,
    //           currency_name,
    //           total_balance: 0
    //         });
    //       }

    //       // 累加币种余额
    //       const currencyData = currencyTotals.get(currency_id);
    //       currencyData.total_balance += amount;
    //     });

    //   });

    //   // 返回 Map 转换成数组的结果
    //   return Array.from(currencyTotals.values());
    // },


    /**
     * 计算每种货币的总余额
     *
     * @param data 包含用户余额信息的数组
     * @returns 返回每种货币的总余额数组
     */
    calculateTotalBalances(data) {
      const currencyTotals = new Map();

      data.forEach(item => {
        item.balances.forEach(balance => {
          const { currency_id, currency_name, balance: rawBalance } = balance;
          const amount = parseFloat(rawBalance) || 0;

          let currencyData = currencyTotals.get(currency_id);
          if (!currencyData) {
            currencyData = { currency_id, currency_name, total_balance: 0 };
            currencyTotals.set(currency_id, currencyData);
          }
          currencyData.total_balance += amount;
        });
      });


      return Array.from(currencyTotals.values());
    },

    // 汇率展示
    exchangeRateAmountCalculation(data, row) {


      let huilv = this.huilvdata.find(item => {
        return (
          item.buy_currency_id == data &&
          item.buy_channel == '189' &&
          item.sale_channel == '189' &&
          item.sale_currency_id == this.forml.currentPopover
        );
      });

      if (huilv) {

        let a = parseFloat(huilv.rate).toFixed(huilv.decimal_place_display);
        this.$set(row, 'rate', a);

        return parseFloat(huilv.rate).toFixed(huilv.decimal_place_display);
      } else {
        console.log('未找到匹配的汇率数据');
        // 在这里可以设置一个默认值或者抛出一个错误，取决于你的应用逻辑
        // 例如：huilv = { rate: 1 }; // 设置一个默认值
        // 或者 throw new Error('未找到匹配的汇率数据'); // 抛出错误
        row.rate = 1;

        huilv = { rate: 1, buy_currency_name: '港币' };
      }

      return huilv.rate;
    },
    // 金额截取
    amountInterception(data, row) {
      row.amount = parseFloat(data);
      let add = this.formatNumberWithCommas(parseFloat(data).toFixed(parseFloat(this.currentinfo.currency_compute_dot_count)));

      return add;
    },
    amountInterception2(data, row) {
      let daa = parseFloat(data).toFixed(parseFloat(this.currentinfo.currency_compute_dot_count));
      let daa2 = parseFloat(data)

      // row.accounts = daa;
      this.$set(row, 'accounts', daa2);
      let add = this.formatNumberWithCommas(daa);
      return add;
    },

    formatNumberWithCommas(number) {
      // 将数字转换为字符串
      let numberStr = number.toString();

      // 使用正则表达式从右向左每三位添加一个逗号
      let formattedStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return formattedStr;
    },


    calculateTotalAmount(assets, liabilities, costs) {
      // 用 Map 存储每种币种的计算结果
      const resultMap = new Map();

      // 处理资产数组
      assets.forEach(item => {
        const { currency_id, currency_name, total_balance } = item;

        const validBalance = parseFloat(total_balance) || 0; // 转换 total_balance，默认值为 0

        if (!resultMap.has(currency_id)) {
          resultMap.set(currency_id, {
            currency_id,
            currency_name,
            total_balance: validBalance // 初始化金额
          });
        } else {
          // 累加资产金额
          resultMap.get(currency_id).total_balance += validBalance;
        }
      });

      // 处理成本数组
      costs.forEach(item => {
        const { currency_id, currency_name, total_balance } = item;

        const validBalance = parseFloat(total_balance) || 0;

        if (!resultMap.has(currency_id)) {
          resultMap.set(currency_id, {
            currency_id,
            currency_name,
            total_balance: validBalance // 初始化金额
          });
        } else {
          // 累加成本金额
          resultMap.get(currency_id).total_balance += validBalance;
        }
      });

      // 处理负债数组
      liabilities.forEach(item => {
        const { currency_id, currency_name, total_balance } = item;

        const validBalance = parseFloat(total_balance) || 0;

        if (!resultMap.has(currency_id)) {
          resultMap.set(currency_id, {
            currency_id,
            currency_name,
            total_balance: -validBalance // 初始化金额，负债需要减去
          });
        } else {
          // 减去负债金额
          resultMap.get(currency_id).total_balance -= validBalance;
        }
      });

      // 将 Map 转换为数组并返回
      return Array.from(resultMap.values());
    },


    // 计算总账户折算金额

    sumAccounts(data) {
      if (!Array.isArray(data) || data.length === 0) {
        return 0;
      }
      return data.reduce((total, item) => {
        const accountValue = parseFloat(item.amount);
        // 输出调试信息（在实际应用中应该移除这些行）
        console.log(`Parsing "${item.amount}" to number:`, accountValue);

        // 检查是否得到了 NaN，如果是则忽略该值
        if (isNaN(accountValue)) {
          console.warn(`Warning: "${item.amount}" is not a valid number and will be ignored.`);
          return total; // 返回当前的总和，不添加该值
        }

        return total + accountValue;
      }, 0);
    }



  },


};

</script>
<style lang="less" scoped>
.red-background {
  background-color: red;
}

.container-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .left {
    margin-right: 20px;
    width: 10vw;
    display: flex;
    align-items: center;

    .left-title {
      font-size: 18px;
      width: 10vw;
    }

    .left-content {
      width: 90%;
    }
  }

  .right {
   
   width: 90vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    

    .right-title {
      font-size: 18px;
      width: 10%;
      white-space: nowrap;
    }

    .right-content {
      width: 90%;
    display: flex;
      overflow-x: auto;
    align-items: center;
    justify-content: flex-start;
      .right-item {
   
        white-space: nowrap;
        cursor: pointer;
        max-width: 600px;
        min-width: 100px;
        height: 50px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;
        box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
      }
    }

  }
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .content-item {
    width: 31%;
    margin: 10px;
  }
}


//顶部样式 选择商家的样式 和店铺的样式
.search-box {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .search-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        > :nth-child(1) {
          width: 80px;
        }
      }
      .search-item1 {
        width: calc(100% - 200px);
        display: flex;
        align-items: center;
        .selectStore {
          width: 80px;
        }
      }
    }
    .account-balance-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      /deep/.el-tabs__nav-scroll {
        padding: 10px 0;
      }
      .tabs-container {
        width: 100%;
        height: 100%;
        /deep/.el-tabs__item {
          font-size: 16px;
        }
        .currency-box {
          padding: 20px 5px;
          box-sizing: border-box;
          overflow: auto;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          .currency-li {
            width: 19.4%;
            margin: 10px 5px 0;
            cursor: pointer;
            display: flex;
            border: 1px solid #000;
            padding: 0 0 0 20px;
            align-items: center;
            box-sizing: border-box;
            > div {
              color: #333333;
              margin-left: 10px;
            }
            .currency-name {
              padding: 5px 0;
              align-items: center;
              > div {
                height: 30px;
                line-height: 30px;
                padding: 0 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }

.popover-content {
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 20px 0;
  box-sizing: border-box;

  > div {
    width: 90%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}
.popover-content1 {
  width: 90%;
  overflow-x: auto;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  > div {
    min-width: 100px;
    max-width: 500px;
    white-space: nowrap;
    padding: 0 20px;
    height: 50px;
    box-sizing: border-box;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    box-shadow: 0px 0px 5px 0px rgb(135, 135, 135);
  }
}

</style>