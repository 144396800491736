<template>
    <div class="storeGrouping">
        <el-card>
            <el-button @click="addStore" type="primary">添加分组</el-button>
        </el-card>

        <template>
    <el-table
      :data="groupdata"
      style="width: 100%">
      <el-table-column
        prop="group_name"
        label="组名"
       >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">

            <el-button size="mini" @click="_editstoregroup(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deletegroup(scope.row)" >删除</el-button>
        </template>

      </el-table-column>

    </el-table>
  </template>





   <!-- 添加分组的弹窗 -->
    <el-dialog title="添加分组" :visible.sync="addGroupingVisible">
        <el-form :model="addGroupingForm" label-width="120px">
            <el-form-item label="分组名称">
                <el-input v-model="addGroupingForm.group_name"></el-input>
            </el-form-item>
        </el-form>
        <template>
            <div class="storeBox">
                <div class="box-container" v-for="(item,index) in storeGroupingList" :key="index">
                   <div class="box-title"> {{item.tenant_name}}</div>
                    <div class="box-item">
                        <div class="item-content" 
                        :id="jtem.store_id"
                        :class="{ selected: isSelected(jtem.store_id) }"
                        @click="toggleSelect(jtem.store_id)" 
                         v-for="(jtem,jndex) in item.stores" :key="jndex"
                         >
                          
                        {{jtem.store_name}}

                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addGroupingVisible = false,selectedStoreIds=[],addGroupingForm.group_name=''">取 消</el-button>
            <el-button type="primary" @click="_addstoregroup">确 定</el-button>
        </div>
    </el-dialog>
 <!-- 修改弹窗 -->
    <el-dialog title="修改分组" :visible.sync="addGroupingVisible2">
        <el-form :model="addGroupingForm" label-width="120px">
            <el-form-item label="分组名称">
                <el-input v-model="addGroupingForm.group_name2"></el-input>
            </el-form-item>
        </el-form>
        <template>
            <div class="storeBox">
                <div class="box-container" v-for="(item,index) in storeGroupingList" :key="index">
                   <div class="box-title"> {{item.tenant_name}}</div>
                    <div class="box-item">
                        <div class="item-content" 
                        :id="jtem.store_id"
                        :class="{ selected: isSelected2(jtem.store_id) }"
                        @click="toggleSelect2(jtem.store_id)" 
                         v-for="(jtem,jndex) in item.stores" :key="jndex"
                         >
                          
                        {{jtem.store_name}}

                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div slot="footer" class="dialog-footer">
            <el-button @click="addGroupingVisible2 = false,selectedStoreIds2=[],group_id='',addGroupingForm.group_name2=''">取 消</el-button>
            <el-button type="primary" @click="_addstoregroup2">确 定</el-button>
        </div>
    </el-dialog>
    </div>
</template>
<script>
 export default {
 data() {
    return {
        addGroupingVisible: false,
        addGroupingVisible2: false,
        group_id:"",
        addGroupingForm: {
            group_name: "",
             group_name2: ""
        },
        storeGroupingList: [], // 假设这是从父组件传入或通过其他方式获取的数据
       selectedStoreIds: [],  // 存储被选中的 store_id
       selectedStoreIds2: [],  // 修改选中的 store_id

       groupdata: [], // 存储分组信息


         }
    },
    mounted() {
        // 获取所有的商家和店铺信息
        this.getStoreGroupingList();
        // 查看分组信息
        this._getlookgroup()
    },
methods: {
    // 删除分组
    async deletegroup(row) {
        const { group_id } = row;
        this.$http.delete('/api/StoreGroup', {data:{ group_id: group_id }})
      .then(res => {
        if (res.data.code !== 1) {
          return this.$message.error(res.data.message);
        }
        this.$message.success('分组删除成功');
        // 重新获取商家店铺信息
        this._getlookgroup()
      })
      .catch(err => {
        console.error(err);
        this.$message.error('删除失败，请重试');
      });
    },
    // 获取所有商家和店铺的信息
    // 编辑分组
    _editstoregroup(row) {
        this.addGroupingForm.group_name2 = row.group_name;
        let dataa = []
        this.group_id = row.group_id;
        row.store.forEach(store => {
            dataa.push(store.store_id);
        });
        // 初始化选中的 store_id 为当前行的 store_ids
        this.selectedStoreIds2 =dataa;
        console.log(this.selectedStoreIds2, "商家店铺分组3");
        
        this.addGroupingVisible2 = true; // 显示弹窗
    },
    // 修改分组发送请求
     async _addstoregroup2() {
        const selectedStoreIds = this.selectedStoreIds2; // 获取选中的 store_id
        this.$http.post('/api/StoreGroup/Modify', { group_id: this.group_id,store_id: selectedStoreIds, group_name: this.addGroupingForm.group_name2 })
      .then(res => {
        if (res.data.code !== 1) {
          return this.$message.error(res.data.message);
        }
        this.$message.success('分组修改成功');
        this.addGroupingVisible2 = false; // 关闭弹框
        this.group_id = ''; // 清除 group_id
        this._getlookgroup() // 重新获取商家店铺信息


      })
      .catch(err => {
        console.error(err);
        this.$message.error('添加失败，请重试');
      });
     },



    // 查看分组
   async _getlookgroup(){
     const {data:res}  = await this.$http.get('/api/StoreGroup/GetGroup')
        if (res.code !== 1) return this.$message.error(res.message);
        this.groupdata = res.data;
        console.log(res.data, "商家店铺分组2");

    },

    // 添加分组
    _addstoregroup(){
        const selectedStoreIds = this.selectedStoreIds; // 获取选中的 store_id
    this.$http.post('/api/StoreGroup/Add', { store_id: selectedStoreIds, group_name: this.addGroupingForm.group_name })
      .then(res => {
        if (res.data.code !== 1) {
          return this.$message.error(res.data.message);
        }
        this.$message.success('分组添加成功');
        this.selectedStoreIds = []; // 清除选中的 store_id
        this.addGroupingForm.group_name = ''; // 清除输入框内容
        this.addGroupingVisible = false; // 关闭弹框
        this._getlookgroup()
      })
      .catch(err => {
        console.error(err);
        this.$message.error('添加失败，请重试');
      });
    },
    addStore() {
       this.addGroupingVisible = true;
    },
    // 获取所有的商家和店铺
   async getStoreGroupingList() {
      const {data:res}  = await this.$http.get("/api/Tenant")
        if (res.code !== 1) return this.$message.error(res.message);
        this.storeGroupingList = res.data;
        console.log(this.storeGroupingList, "商家店铺1");
     },
      // 切换选中状态
    toggleSelect(storeId) {
      const index = this.selectedStoreIds.indexOf(storeId);
      if (index === -1) {
        this.selectedStoreIds.push(storeId);
      } else {
        this.selectedStoreIds.splice(index, 1);
      }
    },
    toggleSelect2(storeId) {
      const index = this.selectedStoreIds2.indexOf(storeId);
      if (index === -1) {
        this.selectedStoreIds2.push(storeId);
      } else {
        this.selectedStoreIds2.splice(index, 1);
      }
    },
    // 检查 store_id 是否被选中
    isSelected(storeId) {
        
      return this.selectedStoreIds.includes(storeId);
    },
    isSelected2(storeId) {
        
        return this.selectedStoreIds2.includes(storeId);
      },
 }
}
</script>
<style lang="less" scoped>
// 选中的样式
.selected {
  background-color: red;
  color: white;
}
 .storeBox {
   width: 100%;
   .box-container {
    width: 100%;
    margin-top: 50px;
    .box-title {
        font-size: 20px;
        font-weight: 700;
        color: #333;
    }
    .box-item {
        margin-top: 20px;
        border: #333 1px solid;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .item-content {
            margin: 10px;
            padding: 5px;
            border-radius: 10px;
            border: #333 1px solid;
         }
     }

   }
}
</style>