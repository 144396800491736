<!--
作用：主界面
auth：xzl 2022-7-22 -->
<template>
  <div>
    <!-- 头部 -->
    <el-header>
      <div class="toggle-button">
        <i
          style="cursor: pointer"
          :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          @click="togaeCollapse"
        ></i>
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <div>
        <i class="el-icon-s-custom"></i>
        <span style="font-size: 14px; margin-right: 20px">
          {{ loginUserInfo.nick }}
        </span>
        <el-button icon="el-icon-switch-button" type="text" @click="logout"
          >退出登录</el-button
        >
      </div>
    </el-header>
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '210px'">
        <el-menu
          :collapse="isCollapse"
          :collapse-transition="false"
          :router="true"
          :default-active="activePath"
          background-color="#333744"
          text-color="#fff"
          active-text-color="#aeae13"
          :unique-opened="true"
        >
          <!-- 一级菜单  -->
          <el-submenu
            v-for="item in menuList"
            :key="item.menu_code"
            :index="item.menu_code"
          >
            <!-- 一级菜单的模板区域 -->
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.menu_name }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
              v-for="subItem in item.children"
              :key="subItem.menu_code"
              :index="'/home/' + subItem.menu_code"
              @click="saveNavState(subItem)"
            >
              <!-- 二级菜单的模板区域 -->
              <template #title>
                <i :class="subItem.icon"></i>
                <span>{{ subItem.menu_name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 内容主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menuList: [
        {
          menu_code: "statistics",
          menu_name: "统计",
          icon: "el-icon-s-data",
          children: [
            {
              menu_code: "accountBalance",
              menu_name: "店铺账户",
              icon: "el-icon-notebook-2",
            },
            {
              menu_code: "tradingTicket",
              menu_name: "交易单",
              icon: "el-icon-notebook-1",
            },
            {
              menu_code: "transactionAccounting",
              menu_name: "往来记账",
              icon: "el-icon-s-order",
            },
            {
              menu_code: "storeGrouping",
              menu_name: "店铺分组",
              icon: "el-icon-document-copy",
            },
            {
              menu_code: "myAssets",
              menu_name: "我的资产",
              icon: "el-icon-coin",
            },
          ],
        },
        {
          menu_code: "usdtTrack",
          menu_name: "虚拟币追踪",
          icon: "el-icon-s-data",
          children: [
            {
              menu_code: "trackingAddress",
              menu_name: "地址",
              icon: "el-icon-notebook-2",
            },
          ],
        },
        {
          menu_code: "dataStatistics",
          menu_name: "数据统计",
          icon: "el-icon-s-data",
          children: [
            {
              menu_code: "dataReport",
              menu_name: "交易报告",
              icon: "el-icon-notebook-2",
            },
            {
              menu_code: "currencyStatistics",
              menu_name: "币种统计",
              icon: "el-icon-notebook-1",
            },
          ],
        },
        {
          menu_code: "customerManagementModule",
          menu_name: '客户管理',
          icon: 'el-icon-s-custom',
          children: [
            {
              menu_code: 'UserProfile',
              menu_name: '客户查询',
              icon: 'el-icon-user-solid'
            },
            {
              menu_code: 'customerWalletAddress',
              menu_name: '客户钱包地址',
              icon: 'el-icon-user'
             }  
            
          ],
        },
        {
          menu_code: "assetStatisticsOfTradingCenter",
          menu_name: '李工交易中心',
          icon: 'el-icon-s-data',
          children: [
            {
              menu_code: 'assetStatistics',
              menu_name: '资产统计',
              icon: 'el-icon-notebook-1'
            },
          ]
        },
        {
        menu_code: "systemEquipmentManagement",
          menu_name: '系统设备管理',
          icon: 'el-icon-setting',
          children: [
            {
              menu_code: 'listOfIoTDevices',
              menu_name: '物联设备列表',
              icon: 'el-icon-user'
            },
            
            
          ],
        
        }
        //... 其他一级菜单
      ],
      // 默认不折叠
      isCollapse: false,
      // 被激活导航地址
      activePath: "",
      loginUserInfo: { nick: "" },
      tags: [],
    };
  },
  created() {
    this.activePath = window.localStorage.getItem("activePath");
    // 获取登录用户信息
    this.getLoginUserInfo();
  },
  methods: {
    // 菜单的水平折叠与展开
    togaeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    // 保存连接的激活地址
    saveNavState(menuitem) {
      console.log(menuitem);
      // 保存激活的菜单地址
      if (this.tags.indexOf(menuitem) >= 0)
        this.tags.splice(this.tags.indexOf(menuitem), 1);
      this.tags.push(menuitem);
    },
    async logout() {
      const confirmResult = await this.$confirm("确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel

      if (confirmResult == "confirm") {
        // 清空token、school_id、school_name
        window.localStorage.clear();
        this.$router.push("/login");
      }
    },
    //登录用户信息
    async getLoginUserInfo() {
      // await this.$http.get("wallet/swagger/v1/swagger.json", {
      //   params: { storeExchangeId: this.detailsInfo.store_exchange_id },
      // });
      const { data: res } = await this.$http.get("User");
      if (res.code == -1) return this.$message.error(res.message);
      console.log("登录用户=====>", res.data);
      // 假设 res.data.tenants 是从服务器获取的数据
      const tenantsJson = JSON.stringify(res.data.tenants);
      // 存储到本地存储中
      localStorage.setItem("loginTenantsInfo", tenantsJson);
      this.loginUserInfo = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
}

.el-aside {
  height: calc(100vh - 60px); //减去header的高度60
  background-color: #333744;

  .el-menu {
    border: none;
  }
}

.el-header {
  //底部边框2px的半透明
  // border-bottom: 2px solid rgba(234, 237, 241, 0.5f);
  height: 60px; //设置为固定高度，方便计算菜单和工作区的高度，从而实现只有工作区滚动效果
  background-color: #3c3c3c;
  color: #bbbbbb;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  font-size: 20px;

  //logo
  img {
    height: 30px;
    margin-left: 18px;
  }

  .toggle-button {
    margin-left: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2em;
    // span {
    //   font-size: 12px;
    // }
  }

  div {
    display: flex;
    align-items: center;

    button {
      color: #bbbbbb;
    }
  }
}
.el-menu {
  background-color: #333744;
  color: #fff;
}
/* 设置侧边栏样式 */
.el-menu {
  height: 100%;

}

/* 设置子菜单标题靠左显示 */
/deep/ .el-submenu__title {
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: flex-start; /* 水平靠左 */
  text-align: left; /* 文本靠左显示 */
  padding-left: 20px; /* 左侧内边距 */
}

/* 设置子菜单项的子菜单项靠左显示 */
.el-submenu .el-menu-item {
  text-align: left; /* 文本靠左显示 */
  padding-left: 40px; /* 左侧内边距 */
}

/* 设置子菜单项的子菜单项标题靠左显示 */
.el-menu-item .el-menu-item__title {
  text-align: left; /* 文本靠左显示 */
  padding-left: 40px; /* 左侧内边距 */
}
.el-menu .el-menu-item.is-active,
.el-menu .el-submenu__title.is-active {
  background-color: #333744;
  color: #aeae13;
}
.el-main {
  background-color: #eaedf1;
  height: calc(100vh - 60px); //减去header的高度60
}

.iconfont {
  margin-right: 10px;
}

// 选择的平台显示样式
.select-platform {
  color: #aeae13;
  cursor: pointer;
}

// 未选择的平台显示样式
.unselect-platform {
  color: #bbbbbb;
  cursor: pointer;
}
</style>
