<template>
  <div class="dataReport">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据统计</el-breadcrumb-item>
      <el-breadcrumb-item>交易报告</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="container">
      <div class="block">
        <el-card class="box-card">
          <div>
            <el-form label-width="80px" :model="formLabelAlign">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="开始时间:">
                    <el-date-picker
                      v-model="formLabelAlign.startime"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="结束时间:">
                    <el-date-picker
                      v-model="formLabelAlign.endtime"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="门店:">
                    <el-cascader
                      v-model="storeList"
                      :options="cascaderOptions"
                      :props="{
                        checkStrictly: false,
                        multiple: true,
                        emitPath: false,
                      }"
                      placeholder="请选择"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-button
                    type="primary"
                    @click="_getstoreCountDate"
                    icon="el-icon-search"
                    >搜索</el-button
                  >
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-card>
        <el-card class="box-card">
          <el-row class="demo-box">
            <el-col :span="12">
              <div style="height: 100%; overflow: hidden">
                <el-table
                  :data="tableData"
                  header-align="center"
                  style="width: 100%"
                >
                  <el-table-column label="买入" header-align="center">
                    <el-table-column prop="daicheng"> </el-table-column>
                    <el-table-column
                      label="款额(港币)"
                      header-align="center"
                      align="right"
                      prop="daijia"
                    >
                    <template  slot-scope="scope">
                      <span :style="{color:getColor(scope.row.daijia)}">{{ formatAmount(scope.row.daijia) }}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                      label="交易宗数"
                      header-align="center"
                      align="right"
                      prop="zongshu"
                    >
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="卖出" header-align="center">
                    <el-table-column
                      label="款额(港币)"
                      header-align="center"
                      align="right"
                      prop="maichudaijia"
                    >
                    <template  slot-scope="scope">
                      <span :style="{color:getColor(scope.row.maichudaijia)}">{{ formatAmount(scope.row.maichudaijia) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="交易宗数"
                      header-align="center"
                      align="right"
                      prop="maichuzongshu"
                    >
                    </el-table-column>
                  </el-table-column>
                </el-table>

                <el-table
                  :data="tableData2"
                  border
                  header-align="center"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="daicheng"
                    width="120"
                    header-align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    label="成功交易总数"
                    header-align="center"
                    align="right"
                    prop="daijia"
                  ></el-table-column>
                  <el-table-column
                    label="拒绝交易总数"
                    header-align="center"
                    align="right"
                    prop="zongshu"
                  ></el-table-column>
                </el-table>
              </div>
            </el-col>

            <el-col :span="12" style="background-color: brown">
              <div style="height: 100%; overflow: hidden">
                <el-table
                  :data="tableData3"
                  header-align="center"
                  style="width: 100%"
                >
                  <el-table-column label="汇出的汇款" header-align="center">
                    <el-table-column prop="daicheng"> </el-table-column>
                    <el-table-column
                      label="款额(港币)"
                      header-align="center"
                      align="right"
                      prop="huichugangbi"
                    >
                    <template  slot-scope="scope">
                      <span :style="{color:getColor(scope.row.huichugangbi)}">{{ formatAmount(scope.row.huichugangbi) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="交易宗数"
                      header-align="center"
                      align="right"
                      prop="huichuzongshu"
                    >
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="汇入的汇款" header-align="center">
                    <el-table-column
                      label="款额(港币)"
                      header-align="center"
                      align="right"
                      prop="huirugangbi"
                    >
                    <template  slot-scope="scope">
                      <span :style="{color:getColor(scope.row.huirugangbi)}">{{ formatAmount(scope.row.huirugangbi) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="交易宗数"
                      header-align="center"
                      align="right"
                      prop="huiruzongshu"
                    >
                    </el-table-column>
                  </el-table-column>
                </el-table>
                <el-table
                  :data="tableData4"
                  border
                  header-align="center"
                  style="width: 100%"
                >
                  <el-table-column
                    label="成功交易总数"
                    header-align="center"
                    align="right"
                    prop="chenggo"
                  ></el-table-column>
                  <el-table-column
                    label="拒绝交易总数"
                    header-align="center"
                    align="right"
                    prop="zongshu"
                  ></el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div style="margin-top: 20px">
                <el-table
                  :data="tableData5"
                  boder
                  header-align="center"
                  style="width: 100%"
                >
                  <el-table-column label="现金对汇款" align="center">
                    <el-table-column
                      prop="zhuanghu"
                      align="center"
                    ></el-table-column>
                    <el-table-column label="总额" prop="huikuan" align="center">
                      <template slot-scope="scope">
                        <span :style="{color:getColor(scope.row.huikuan)}">
                        {{ formatAmount(scope.row.huikuan) }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="笔数"
                      prop="bishu"
                      align="center"
                    ></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="margin-top: 20px">
                <el-table
                  :data="tableData6"
                  boder
                  header-align="center"
                  style="width: 100%"
                >
                  <el-table-column
                    label="现金对现金-买入卖出金额汇总"
                    align="center"
                  >
                    <el-table-column
                      label="买入金额汇总"
                      prop="total_buy_amount"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <span :style="{color:getColor(scope.row.total_buy_amount)}">
                        {{ formatAmount(scope.row.total_buy_amount) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="币种"
                      prop="currency_name"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      label="卖出金额汇总"
                      prop="total_sale_amount"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <span :style="{color:getColor(scope.row.total_sale_amount)}">
                        {{ formatAmount(scope.row.total_sale_amount) }}
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column label="买入减卖出" align="center">
                      <template slot-scope="scope">
                        <span :style="{color:getColor((
                              Number(scope.row.total_buy_amount) -
                              Number(scope.row.total_sale_amount)
                            ).toFixed(2))}">
                        {{
                          formatAmount(
                            (
                              Number(scope.row.total_buy_amount) -
                              Number(scope.row.total_sale_amount)
                            ).toFixed(2)
                          )
                        }}</span>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      tableData: [
        {
          daicheng: "人民币",
        },
        {
          daicheng: "其他货币",
        },
        {
          daicheng: "总数",
        },
      ],
      tableData2: [
        {
          daicheng: "人民币",
        },
        {
          daicheng: "其他货币",
        },
        {
          daicheng: "总数",
        },
      ],
      tableData3: [
        {
          daicheng: "其他金钱服务经营者",
        },
        {
          daicheng: "个别人士",
        },
        {
          daicheng: "其他",
        },
        {
          daicheng: "总数",
        },
      ],
      tableData4: [],
      tableData5: [
        {
          zhuanghu: "微信",
          huikuan: "",
          bishu: "",
        },
        {
          zhuanghu: "支付宝",
          huikuan: "",
          bishu: "",
        },
        {
          zhuanghu: "银行卡",
          huikuan: "",
          bishu: "",
        },
      ],
      tableData6: [],
      formLabelAlign: {
        store: "",
        daterangeTime: [],
        contents: "",
        startime: this.getThreeMonthsAgo(),
        endtime: this.getCurrentTime(),
      },

      cascaderOptions: [],

      storeList: [],
    };
  },
  mounted() {
    this.formLabelAlign.startime = this.getThreeMonthsAgo();
    this.formLabelAlign.endtime = this.getCurrentTime();
    this._getTenant();
  },
  methods: {
    // 整数时显示红色，负数时显示绿色
    getColor(amount) {
      return amount >= 0 ? "green" : "red";
    },
    _getTenant() {
      this.$http.get("/api/Tenant").then((res) => {
        console.log(res.data.data, "商家列表");
        // 转换为 el-cascader 的数据结构
        const cascaderData = res.data.data.map((tenant) => ({
          value: tenant.tenant_id,
          label: tenant.tenant_name,

          children: tenant.stores.map((store) => ({
            value: store.store_id,
            label: store.store_name,
          })),
        }));
        console.log(cascaderData, "cascaderData");
        this.cascaderOptions = cascaderData;
      });
    },
    getCurrentTime() {
      const currentDate = moment();
      return currentDate.format("YYYY-MM-DD");
    },
    getThreeMonthsAgo() {
      const currentDate = moment();
      currentDate.subtract(3, "months");

      return currentDate.format("YYYY-MM-DD");
    },

    //金额格式化
    formatAmount(amount) {
      // 去除小数点前后多余的0
      amount = parseFloat(amount);
      if (isNaN(amount)) return "";
      var parts = String(amount).split(".");
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? "." + parts[1] : "";

      // 每三位添加一个逗号
      integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return integerPart + decimalPart;
    },
    _getstoreCountDate() {
      // 82875453 89332217.39 -6456764.390000001
      // console.log(82875453 - 89332217.39);

      const startTime = moment(this.formLabelAlign.startime);
      const endTime = moment(this.formLabelAlign.endtime);
      const newStartTime = startTime.format("YYYY-MM-DD") + " 00:00:00";
      const newEndTime = endTime.format("YYYY-MM-DD") + " 23:59:59";
      this.$http
        .post("api/Report/Report", {
          st: newStartTime,
          et: newEndTime,
          storeList: this.storeList,
        })
        .then(({ data: res }) => {
          if (res.code == -1) return this.$message.error(res.message);
          const countdata = res.data;
          let mairugangbi = [
            {
              daicheng: "人民币",
              daijia: countdata.cash2Cash_CNY2HKD_HKDSum,
              zongshu: countdata.cash2Cash_CNY2HKD_Count,
              maichudaijia: 
                countdata.cash2Cash_HKD2CNY_HKDSum,
              maichuzongshu: countdata.cash2Cash_HKD2CNY_Count,
            },
            {
              daicheng: "其他货币",
              daijia: countdata.cash2Cash_Other2HKD_HKDSum,
              zongshu: countdata.cash2Cash_Other2HKD_Count,
              maichudaijia:
                countdata.cash2Cash_HKD2Other_HKDSum,
              maichuzongshu: countdata.cash2Cash_HKD2Other_Count,
            },
            {
              daicheng: "总数",
              daijia: 
                countdata.cash2Cash_CNY2HKD_HKDSum +
                  countdata.cash2Cash_Other2HKD_HKDSum
              ,
              zongshu:
                countdata.cash2Cash_CNY2HKD_Count +
                countdata.cash2Cash_Other2HKD_Count,
              maichudaijia: 
                countdata.cash2Cash_HKD2CNY_HKDSum +
                  countdata.cash2Cash_HKD2Other_HKDSum
              ,
              maichuzongshu:
                countdata.cash2Cash_HKD2CNY_Count +
                countdata.cash2Cash_HKD2Other_Count,
            },
          ];
          this.tableData = mairugangbi;
          let mairugangbi2 = [
            {
              daicheng: "人民币",
              daijia:
                countdata.cash2Cash_CNY2HKD_Count_12 +
                countdata.cash2Cash_HKD2CNY_Count_12,
              zongshu: "",
            },
            {
              daicheng: "其他货币",
              daijia:
                countdata.cash2Cash_Other2HKD_Count_12 +
                countdata.cash2Cash_HKD2Other_Count_12,
              zongshu: "",
            },
            {
              daicheng: "总数",
              daijia:
                countdata.cash2Cash_CNY2HKD_Count_12 +
                countdata.cash2Cash_HKD2CNY_Count_12 +
                countdata.cash2Cash_Other2HKD_Count_12 +
                countdata.cash2Cash_HKD2Other_Count_12,
              zongshu: "",
            },
          ];
          this.tableData2 = mairugangbi2;
          // 处理金额
          let inputNum = parseFloat(
            countdata.cash2Trans_All2CNY_CNYSum * 1.098901099
          );
          let inputNum2 = parseFloat(
            countdata.surplus2Cash_CNY2All_CNYSum * 1.098901099
          );
          let roundedInputNum = Math.round(inputNum);
          let roundedInputNum2 = Math.round(inputNum2);
          let mairugangbi3 = [
            {
              daicheng: "其他金钱服务经营者",
            },
            {
              daicheng: "个别人士",
              huichugangbi: roundedInputNum, //1
              huichuzongshu: countdata.cash2Trans_All2CNY_Count, //2
              huirugangbi: roundedInputNum2, //3
              huiruzongshu: countdata.surplus2Cash_CNY2All_Count, //4
            },
            {
              daicheng: "其他",
            },
            {
              daicheng: "总数",
            },
          ];
          this.tableData3 = mairugangbi3;
          this.tableData4 = [
            {
              chenggo:
                countdata.cash2Trans_All2CNY_Count_8 +
                countdata.surplus2Cash_CNY2All_Count_8,
            },
          ];
        })
        .then(() => {
          this.$http
            .post("api/Report/Report2", {
              st: newStartTime,
              et: newEndTime,
              storeList: this.storeList,
            })
            .then(({ data: res }) => {
              if (res.code !== 1) return this.$message.error(res.message);
              console.log(res.data, "数据");
              let count5 = res.data;

              this.tableData5 = [
                {
                  zhuanghu: "微信",
                  huikuan: count5.cash2Trans_WX_SaleAmout_Sum,

                  bishu: count5.cash2Trans_WX_Count,
                },
                {
                  zhuanghu: "支付宝",
                  huikuan: count5.cash2Trans_Alipay_SaleAmout_Sum,
                  bishu: count5.cash2Trans_Alipay_Count,
                },
                {
                  zhuanghu: "银行卡",
                  huikuan: count5.cash2Trans_BankCard_SaleAmout_Sum,
                  bishu: count5.cash2Trans_BankCard_Count,
                },
              ];
            });
        })
        .then(() => {
          this.$http
            .post("api/Report/Report3", {
              st: newStartTime,
              et: newEndTime,
              storeList: this.storeList,
            })
            .then(({ data: res }) => {
              if (res.code !== 1) return this.$message.error(res.message);
              this.tableData6 = res.data;
            });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.dataReport {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.el-table {
  margin-top: 0;
  font-size: 12px;
}

/deep/ .el-range-separator {
  padding: 0px !important;
}
</style>
